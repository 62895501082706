import { AppActions, PAGEWATCHER } from '../Actions/def';
import AppState, { defaultAppState } from '../State/appState';

export default function appReducer(
  state: AppState = defaultAppState(),
  action: AppActions
): AppState {
  switch (action.type) {
    case PAGEWATCHER: {
      return { ...state, pageType: action.payload.pageType };
    }
    default:
      return state;
  }
}
