import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import AggregatesActionGenerator from '../../Aggregates/Actions/AggregatesGen';
import SearchAPI from '../Actions/SearchAPI';
import {
  Search,
  SEARCH,
  SEARCH_WITH_FILTER,
  SearchWithFilter,
} from '../Actions/SearchDef';
import SearchActionGen from '../Actions/SearchGen';
import { SearchResult } from '../State/SearchState';

export function* search(action: Search): any {
  try {
    const url = 'contracts/search/';
    const searchTerm = atob(action.payload.searchTerm);
    const payload = { search_term: searchTerm };
    const response = yield call(SearchAPI.search, url, payload);
    if (response) {
      const parsed = response.results as SearchResult[];
      yield put(SearchActionGen.searchSuccess(parsed));
      yield put(
        AggregatesActionGenerator.searchClauses(response.results, searchTerm)
      );
    } else {
      yield put(SearchActionGen.searchFailure());
    }
  } catch (e) {
    yield put(SearchActionGen.searchFailure());
  }
}

function* searchWithFilter(action: SearchWithFilter): any {
  try {
    const url = 'contracts/search/';
    const response = yield call(SearchAPI.search, url, action.payload);
    if (response) {
      const parsed = response.results;
      if (parsed.length > 0) {
        yield put(SearchActionGen.searchSuccess(parsed));
      } else {
        yield put(SearchActionGen.searchSuccess([]));
        yield put(AggregatesActionGenerator.saveAppliedFilters([]));
        yield put(AggregatesActionGenerator.addFilterSuccess({}));
      }
    } else yield put(SearchActionGen.searchFailure());
  } catch (error) {
    console.error(error);
    yield put(SearchActionGen.searchFailure());
  }
}

export default function* searchPageWatcher() {
  yield all([
    takeLatest(SEARCH, search),
    takeLatest(SEARCH_WITH_FILTER, searchWithFilter),
  ]);
}
