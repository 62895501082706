import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DrawIcon from '@mui/icons-material/Draw';
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';

interface Props {
  tempSignatory: any;
  handleDeleteTempSignatory: (id: string) => void;
  method: string;
  addField: any;
  dropPoint: any;
  serialNumber: number;
}

const AddedSignatoriesList: React.FC<Props> = ({
  tempSignatory,
  handleDeleteTempSignatory,
  method,
  addField,
  dropPoint,
  serialNumber,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleDeleteConfirmation = () => {
    setOpenDeleteModal(false);
    handleDeleteTempSignatory(tempSignatory);
  };

  const dragStart = (e: any) => {
    e.target.style.opacity = 0.5;
    const copy = e.target.cloneNode(true);
    copy.id = 'form-build-drag-image-copy';
    copy.style.width = '250px';
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData('text', '');
  };

  const dragEnd = (e: any, type: any) => {
    addField(type, tempSignatory?.user, dropPoint);
    e.target.style.opacity = 1;
    document.body.removeChild(
      document.getElementById('form-build-drag-image-copy') as Node
    );
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <List
        style={{
          backgroundColor: '#88305f1f',
          borderRadius: '10px',
          margin: '15px 0',
        }}
      >
        <ListItem
          secondaryAction={
            <Stack direction="row">
              {method !== 'Adobe' && (
                <Tooltip
                  title="  Drag and Drop this Signature Box to fix the signature position on
              the document."
                  arrow
                >
                  <div
                    draggable
                    onDragStart={(e) => dragStart(e)}
                    onDragEnd={(e) => dragEnd(e, 'SIGNATURE')}
                  >
                    <IconButton
                      onClick={() => addField('SIGNATURE', tempSignatory?.user)}
                    >
                      <DrawIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          }
        >
          <ListItemAvatar>
            <Stack
              sx={{
                height: '30px',
                width: '30px',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: '#6D264C',
              }}
            >
              <Typography variant="body1">{serialNumber}</Typography>
            </Stack>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack>
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {tempSignatory?.user?.name} -{' '}
                  {tempSignatory?.user?.assignee_type}
                </Typography>
                <Typography>{tempSignatory?.user?.email}</Typography>
              </Stack>
            }
          />
        </ListItem>
      </List>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title="Delete Signatory"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={handleDeleteConfirmation}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected signatory will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default AddedSignatoriesList;
