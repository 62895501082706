export function getUploadedTime(date: string) {
  const currentDate = new Date();
  const statusDate = new Date(date);
  const time = currentDate.getTime() - statusDate.getTime();
  const days = Math.round(time / (1000 * 3600 * 24));
  if (days === 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Yesterday';
  } else {
    return getDate(date);
  }
}

export function getDateFormat(dateOfUpload: string) {
  let date, newdate, a: any, value;
  const monthNames = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  if (dateOfUpload !== null) {
    date = dateOfUpload.split('T')[0];
    date = date.toLocaleString();
    date = date.toString();
    a = date.split('-')[1];
    newdate = date.split('-');
    value = newdate[2] + '/' + monthNames[a - 1] + '/' + newdate[0];
  }
  return value;
}

export function getDate(dateOfUpload: string) {
  let date, newdate, a: any, value;
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (dateOfUpload !== null) {
    date = dateOfUpload.split('T')[0];
    date = date.toLocaleString();
    date = date.toString();
    a = date.split('-')[1];
    newdate = date.split('-');
    value = newdate[2] + ' ' + monthNames[a - 1] + ' ' + newdate[0];
  }
  return value;
}

export function getTermDate(termDate: string) {
  const monthArray = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const date = termDate + '';
  const splitStr = date.split('-');
  const month = '' + (monthArray.indexOf(splitStr[1]) + 1);
  const day = splitStr[0];
  const year = splitStr[2];
  const value = day + '/' + month + '/' + year;
  return value;
}

export function humanFileSize(bytes: any, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );
  return bytes.toFixed(dp) + ' ' + units[u];
}

export function truncateFileName(name: string) {
  const str1 = name.slice(0, 12);
  const len = name.length;
  const str2 = name.slice(len - 4, len);
  if (len > 15) {
    return str1 + '....' + str2;
  } else {
    return name;
  }
}

export function truncateString(name: string, size: number) {
  const str1 = name.slice(0, size + 1);
  const len = name.length;
  if (len > size) {
    return str1 + '...';
  } else {
    return name;
  }
}

export function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const todaysDate = dd + '/' + mm + '/' + yyyy;
  return todaysDate;
}

//to copy text in clipboard
export function textCopyToClipboard(text: string) {
  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export function getNormalizedDateToDateString(dateValue: string) {
  //2020-02-01 to 2 FEB 2020
  const dateArray = dateValue.split('-');
  const monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day: string = dateArray[2];
  const year: string = dateArray[0];
  let month = '';
  let monthIndex: number = parseInt(dateArray[1], 10);
  monthIndex = monthIndex - 1;
  month = monthArray[monthIndex];
  const dateString: string = day + ' ' + month + ' ' + year;
  return dateString;
}

export const camelize = (str: any) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[.\s]+/g, ''); // Added condition to replace dots and spaces
};
