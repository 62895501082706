import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import SearchActionGen from '../../Search/Actions/SearchGen';
import { SearchResult } from '../../Search/State/SearchState';
import AggregatesAPI from '../Actions/AggregatesAPI';
import {
  ADDFILTER,
  AddFilter,
  DELETEFILTER,
  DeleteFilter,
  FILTERRESULT,
  FilterResult,
  SEARCHFILTER,
  SearchFilter,
} from '../Actions/AggregatesDef';
import AggregatesActionGenerator from '../Actions/AggregatesGen';
import { AggregateState } from '../State/AggregatesState';
import AggregateUtils from '../Utils/utils';

function* deleteFilter(action: DeleteFilter) {
  const filters = action.payload.filters;
  const path = action.payload.path;
  const sort = action.payload.sort;
  const window = action.payload.window;
  const filterType = action.payload.filterType;
  AggregateUtils.deleteByPath(filters, path, 0);
  yield put(AggregatesActionGenerator.addFilterSuccess(filters));
  yield put(
    AggregatesActionGenerator.filterResult(
      filters,
      sort,
      window,
      true,
      true,
      filterType
    )
  );
}

function* filterResult(action: FilterResult): any {
  const url = 'contracts/search/filter/';
  const sort = action.payload.sort;
  const window = action.payload.window;
  const filters = action.payload.appliedFilters;
  const setToDefault = action.payload.setToDefault;
  const filterType = action.payload.filterType;
  try {
    const object = {
      sort: sort,
      window: window,
      filter: filters,
      filterType: filterType,
    };
    const response = yield call(AggregatesAPI.filterResult, url, object);
    if (response) {
      const result = response as SearchResult[];
      yield put(
        AggregatesActionGenerator.filterResultSuccess(
          sort,
          result.length === 0,
          setToDefault
        )
      );
      if (result.length > 0) {
        yield put(SearchActionGen.searchSuccess(result));
      }
    } else {
      yield put(AggregatesActionGenerator.filterResultFailure());
    }
  } catch (e) {
    yield put(AggregatesActionGenerator.filterResultFailure());
  }
}

export function* searchFilter(action: SearchFilter): any {
  const url = 'contracts/search/filter/aggregatesearch/';
  const payload = action.payload;
  const aggregateType = action.payload.aggregateType;
  const apiObject = {
    value: action.payload.value,
    level: action.payload.level,
    page: action.payload.page,
    sort: action.payload.sort,
    type: action.payload.type,
    filter: action.payload.filter,
    fileId: action.payload.fileId,
    aggregateType: action.payload.aggregateType,
  };
  if (payload.value === '') {
    yield put(AggregatesActionGenerator.searchFilterSuccess([]));
  } else {
    const response = yield call(AggregatesAPI.searchFilter, url, apiObject);
    if (response) {
      const parsed = response as AggregateState[];
      for (let i = 0; i < parsed.length; i++) {
        parsed[i].path =
          aggregateType + '/' + parsed[i].value.replace(/\/\//g, '/');
        parsed[i].childrenCount = 0;
        parsed[i].value = AggregateUtils.getSectionValue(
          parsed[i].value,
          'value'
        );
      }
      yield put(AggregatesActionGenerator.searchFilterSuccess(parsed));
    } else {
      //Nothing
    }
  }
}

function* addFilter(action: AddFilter): any {
  try {
    const modifiedFilters = AggregateUtils.modifyFilter(
      action.payload.clauseType,
      action.payload.value,
      action.payload.isAdd,
      action.payload.currentAppliedFilter
    );
    yield put(AggregatesActionGenerator.addFilterSuccess(modifiedFilters));
    const searchTerm = yield select((state) => state.searchBar.term);
    const payload = { search_term: searchTerm, ...modifiedFilters };
    yield put(SearchActionGen.searchWithFilter(payload));
  } catch (error) {
    console.error(error);
  }
}

export default function* aggregateWatcher() {
  yield all([
    takeLatest(ADDFILTER, addFilter),
    takeLatest(DELETEFILTER, deleteFilter),
    takeLatest(FILTERRESULT, filterResult),
    takeLatest(SEARCHFILTER, searchFilter),
  ]);
}
