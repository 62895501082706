export const contractTabLabel: any = {
  contracts_count: 'All Contracts',
  active: 'Active',
  expiring: 'Expiring this month',
  expired: 'Expired',
  total_contracts_size: 'Used space',
  total_reminders: 'With Reminders',
};

export const contractTableTabLabel: any = {
  all: 'All Contracts',
  active: 'Active Contracts',
  expired: 'Expired contracts',
  my_contracts: 'My Contracts',
};

export const statusFilterList = [
  { value: 'not started', name: 'Not Started' },
  { value: 'processing', name: 'Processing' },
  { value: 'done', name: 'Done' },
];
