import { authApi } from './authApi';

export const fetchTemplates = async (params = ''): Promise<any> => {
  return await authApi.get(`stylus/templates/${params}`);
};

export const fetchTemplateById = async (id: string): Promise<any> => {
  return await authApi.get(`stylus/templates/${id}/`);
};

export const fetchTemplateStatistics = async (): Promise<any> => {
  return await authApi.get('stylus/templates/statistics/');
};

export const deleteTemplate = async (payload: any): Promise<any> => {
  return await authApi.delete('stylus/templates/', { data: payload });
};

export const fetchTemplateTags = async (): Promise<any> => {
  return await authApi.get('stylus/template-tags/');
};

export const addTemplateTags = async (payload: any) => {
  return await authApi.post('stylus/template-tags/', payload);
};

export const getContractTypes = async () => {
  return await authApi.get('admin/contracttype/');
};

export const updateTemplate = async (payload: any) => {
  return await authApi.patch(`stylus/templates/${payload.id}/`, payload.data);
};

export const getTemplateById = async (id: string) => {
  return await authApi.get(`stylus/templates/${id}/`);
};

export const downloadTemplate = async (payload: any) => {
  return await authApi.post('stylus/templates/download-template/', payload);
};
