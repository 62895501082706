import { connect } from 'react-redux';

import AppActionGenerator from '../../App/Actions/actionGen';
import { isRiverusAdmin } from '../../DataDictionary/DDUtils';
import DraftComponent from '../Component/DraftComponent';
import UploadFileDA, { getContracts, uploadContractMetaData } from '../DA';
import { File } from '../State/DraftState';

const SparkMD5 = require('spark-md5');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mapStateToProps(appState: any, ownProps: any) {
  const handleOnChangeFileUpload = async (
    post: any,
    fileType: any,
    callback: any
  ) => {
    try {
      let uploadedFiles: File[] = [];
      let uploadProgress: any = {};
      const files_ = uploadedFiles;
      const files: any = post;
      const uploadProgress_ = uploadProgress;
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = post[i];
          const reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = async () => {
              const hexHash = SparkMD5.hash(reader.result);
              files_.push({
                name: file.name,
                size: file.size,
                type: file.type,
                hash: hexHash,
                status: 'uploading',
                template_type: file['template_type'],
                file_type: fileType ? 'req_form' : 'Contract_Draft',
                ref_draft_id: file.id || undefined,
              });
              uploadProgress_[hexHash] = 0;
              uploadProgress = { ...uploadProgress_ };
              try {
                const response: any = await UploadFileDA.get_s3_presigned_url({
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file['template_type'],
                  file_type: fileType ? 'req_form' : 'Contract_Draft',
                  creation_type: isRiverusAdmin() ? 'system' : 'custom',
                  ref_draft_id: file.id || undefined,
                });
                if (response) {
                  const onHandleFileProgress = {
                    onUploadProgress: function (progressEvent: any) {
                      const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      const uploadProgress_ = uploadProgress;
                      uploadProgress_[hexHash] = percentCompleted;
                      uploadProgress = {
                        ...uploadProgress_,
                      };
                    },
                  };
                  if (response && response.presigned_url) {
                    const s3Info = response.presigned_url;
                    try {
                      delete file.id;
                      const response =
                        await UploadFileDA.upload_file_in_s3_bucket(
                          s3Info,
                          file,
                          onHandleFileProgress
                        );
                      if (response) {
                        callback(s3Info, null);
                      }
                    } catch (error) {
                      console.error(error);
                      callback(null, error);
                    }
                  }
                }
                uploadedFiles = [...files_];
              } catch (error) {
                console.error(
                  '🚀 ~ file: templatesContainer.tsx ~ line 77 ~ handleOnChangeFileUpload ~ error',
                  error
                );
                callback(null, error);
              }
            };
          }
        }
      }
    } catch (error) {
      callback(null, error);
    }
  };

  const uploadContractData = (post: any) => {
    try {
      return uploadContractMetaData(post);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    uploadFile: (post: any, fileType: any, callback: any) =>
      handleOnChangeFileUpload(post, fileType, callback),
    uploadContractData: (post: any) => uploadContractData(post),
    getContracts: () => getContracts(1),
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DraftComponent);
