import { authApi } from '../../Services/authApi';

export default class SearchAPI {
  public static search(url: string, object: any) {
    return authApi
      .post(url, object)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static count(url: string, object: any) {
    return authApi
      .post(url, object)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
