import {
  SEARCH,
  SEARCH_FAILURE,
  SEARCH_SUCCESS,
  SEARCH_WITH_FILTER,
  SearchPageAction,
} from '../Actions/SearchDef';
import SearchState, { defaultSearchState } from '../State/SearchState';

export default function searchReducer(
  state: SearchState = defaultSearchState(),
  action: SearchPageAction
): SearchState {
  switch (action.type) {
    case SEARCH: {
      return {
        ...state,
        searchLoader: true,
        pageSize: action.payload.pageSize,
      };
    }
    case SEARCH_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload.searchResult,
        searchLoader: false,
      };
      // dont wait for old result if new search term added
    }
    case SEARCH_FAILURE: {
      return { ...state, searchLoader: false };
    }
    case SEARCH_WITH_FILTER: {
      return { ...state, searchLoader: true };
    }
    default:
      return state;
  }
}
