import React, { useEffect, useMemo, useState } from 'react';

import { ArticleOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import DownloadInsights from './InsightComponents/saveInsights';
import { download_file } from '../../Draft/Component/Helper';
import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import {
  editContract,
  fetchContractById,
  fetchContractingParty,
  fetchDownloadUrl,
} from '../../Services/DocumentLibrary';

interface MenuOption {
  value: string;
  label: string;
  startIcon?: any;
}

interface Props {
  updatedClauseData: any;
  sentenceData: any;
}

const downloadMenu: MenuOption[] = [
  {
    value: 'download_file',
    label: 'Download file',
    startIcon: <ArticleOutlined />,
  },
];

const DocumentHeader: React.FC<Props> = ({
  updatedClauseData,
  sentenceData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goBackUrl = '/documentlibrary';

  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_by_id'],
    queryFn: async () => await fetchContractById(atob(id as string)),
    enabled: !!atob(id as string),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    resetField,
    setValue,
  } = useForm();

  useEffect(() => {
    if (contract_data && isEdit) {
      setValue('title', contract_data?.title);
    }
  }, [contract_data, isEdit]);

  const { mutate: downloadFileMutate } = useMutation({
    mutationFn: fetchDownloadUrl,
    onSuccess: (response) => {
      download_file(response);
      handleClose();
    },
  });

  const onCancel = () => {
    resetField('title');
    setIsEdit(false);
  };

  const { mutate: updateContract } = useMutation({
    mutationFn: editContract,
    onSuccess: () => {
      enqueueSnackbar('Contract name updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_contract_data_by_id'],
      });
      onCancel();
    },
    onError: (error: any) => {
      const responseData =
        error?.response?.data?.__all__?.[0] ||
        'Failed to update Contract name!';
      enqueueSnackbar(responseData, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: getContractParties } = useQuery({
    queryKey: ['get_contract_party'],
    queryFn: async () => await fetchContractingParty(contract_data?.id),
    enabled: !!contract_data?.id,
  });

  const contracting_parties = useMemo(() => {
    let raw_content = null;
    let edited_content = null;

    if (getContractParties != null) {
      raw_content = getContractParties?.raw_content;
      edited_content = getContractParties?.edited_content;
    }
    if (!raw_content) {
      raw_content = {
        contracting_parties: [],
      };
    }
    if (!edited_content) {
      edited_content = {
        update: [],
      };
    }
    const diff = changesets.unflattenChanges(edited_content.update);
    const changeList = changesets.applyChangeset(raw_content, diff);
    return changeList?.contracting_parties;
  }, [getContractParties]);

  const onSubmit = (data: any) => {
    const payload = {
      id: contract_data?.id,
      body: data,
    };
    if (isDirty) updateContract(payload);
  };

  return (
    <Box>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Link
              href={goBackUrl}
              color="riPrimary.500"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIcon fontSize="small" sx={{ marginRight: '3px' }} />
              Back
            </Link>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            padding="10px 0"
            justifyContent="center"
          >
            {contract_data?.title && !isEdit && (
              <Button
                variant="text"
                sx={{
                  marginRight: 4,
                  fontSize: '16px',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setIsEdit(true)}
                endIcon={<EditOutlinedIcon />}
              >
                {contract_data?.title}
              </Button>
            )}
            {isEdit && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ControlledTextField
                    name="title"
                    control={control}
                    label="Contract Name"
                    fullWidth
                  />
                  <IconButton onClick={onCancel}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Stack>
              </form>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            <Button
              onClick={handleClick}
              variant="text"
              startIcon={<FileDownloadOutlinedIcon />}
              endIcon={<ArrowDropDownIcon />}
            >
              Download
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box width="200px">
                {downloadMenu?.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => downloadFileMutate(contract_data?.id)}
                  >
                    {option.startIcon}
                    <Typography marginLeft={2}> {option.label}</Typography>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem disabled={!updatedClauseData}>
                  <FileDownloadOutlinedIcon />
                  <DownloadInsights
                    insightsData={updatedClauseData}
                    contracting_parties={contracting_parties}
                    sentenceData={sentenceData}
                    quickIntel={{
                      fileName: contract_data?.file_name,
                      metaData: [],
                    }}
                  />
                </MenuItem>
              </Box>
            </Menu>
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
    </Box>
  );
};

export default DocumentHeader;
