import axios from 'axios';

import { authApi } from './authApi';
import AUTH0 from '../auth_config';
import { getLocalStorage } from '../Authentication/Actions/authentication';
import { PresignedDataField } from '../Draft/State/DraftState';

const APIPaths = {
  draftBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
  getRequisition:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/requisition_form/',
  getCompareVersion:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/compare_version/',
  getTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/templates/',
  getChecklist:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_checklist/',
  createChecklist: process.env.REACT_APP_RIVERUS_CLM_API + 'admin/checklist/',
  uploadDocUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  getContractTemplates: process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/',
  getTemplatePresignedURL: `${AUTH0.apiBasePath}stylus/templates/aws/presigned-url/`,
  getS3PresignedURL: `${AUTH0.apiBasePath}stylus/aws/presigned-url/`,
  getOptionalFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/optional_field/',
  saveCompleteDraftFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/bulk_optional_field/',
  getFields:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/get_field',
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Collaborators&assignee_type=internal',
  getApprovals:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  approvalComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval_comment/',
  checklistComments:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/checklist-comment/',
  requestApproval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/request_approval/',
  getDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  getMergeTagDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/merge-tag-deviation/',
  draft_deviation:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_deviation/',
  draft_approval:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft_approval/',
  referenceDocument:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/reference_document/',
  emailApi:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/approval-email/',
  downloadFileUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'stylus/draft_checklist_supportdoc/',
  externalUsers: process.env.REACT_APP_RIVERUS_CLM_API + 'users/external-user/',
  externalUsersList:
    process.env.REACT_APP_RIVERUS_CLM_API + 'users/?assignee_type=external',
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

const axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

export const fetchExecutedContractsById = async (id: string) => {
  const res = await fetch(
    `${APIPaths.getContractTemplates}download/${id}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchSupportDocsDownloadUrl = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.downloadFileUrl}${id}/download-checklist/`,
    APIConfig()
  );
  return response.json();
};

export const editDraftData = (payload: any) => {
  const response = axios.patch(
    `${APIPaths.draftBaseUrl}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const fetchCollaborators = async () => {
  const res = await fetch(`${APIPaths.getCollaborators}`, APIConfig());
  return res.json();
};

export const fetchTemplate = async () => {
  const response = await fetch(APIPaths?.getTemplates, APIConfig());
  return response.json();
};

export const fetchDrafts = async (params?: string) => {
  const res = await fetch(
    `${APIPaths.draftBaseUrl}get_latest/?filter_parameter=Group${
      params ? params : ''
    }`,
    APIConfig()
  );
  return res.json();
};

export const createDraft = (payload: any) => {
  return axios.post(APIPaths.draftBaseUrl, payload, APIConfig());
};

export const sendEmail = (payload: any) => {
  return axios.post(APIPaths.emailApi, payload, APIConfig());
};

export const updateDraft = (id: string, body: any) => {
  return axios.put(`APIPaths.createApproval${id}/`, body, APIConfig());
};

export const fetchDraftById = async (id: string) => {
  const res = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return res.json();
};

export const fetchStatistics = async () => {
  const res = await fetch(`${APIPaths.draftBaseUrl}statistics/`, APIConfig());
  return res.json();
};

export const deleteDraft = async (ids: string[]) => {
  const data = { delete_list: ids, type: 'drafts' };
  return axios.delete(`${APIPaths.draftBaseUrl}`, {
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
    data,
  });
};

export const fetchVersionHistory = async (id: string) => {
  const res = await fetch(`${APIPaths.draftBaseUrl}?search=${id}`, APIConfig());
  return res.json();
};

export const fetchRequisitionVersionHistory = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}?search=${id}`,
    APIConfig()
  );
  return response.json();
};

export const deleteCompareVersion = async (ids: string[]) => {
  const data = { delete_list: ids };
  return axios.delete(`${APIPaths.getCompareVersion}`, {
    ...APIConfig(),
    data,
  });
};

export const deleteDraftVersionHistory = async (ids: string[]) => {
  const data = { delete_list: ids, type: 'version_drafts' };
  return axios.delete(`${APIPaths.draftBaseUrl}`, {
    ...APIConfig(),
    data,
  });
};

export const fetchChecklistData = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getChecklist}?draft=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchRequisitionVersionById = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}${id}/`,
    APIConfig()
  );
  return response.json();
};

export const fetchDraftVersionId = async (id: string) => {
  const response = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return response.json();
};

export const fetchCompareVersionHistory = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getCompareVersion}?search=${id}`,
    APIConfig()
  );
  return response.json();
};

export const updateVersion = (id: string) => {
  const response = axios.patch(
    `${APIPaths.getRequisition}/${id}/`,
    {
      draft_created: true,
    },
    APIConfig()
  );
  return response;
};

export const updateChecklist = (payload: any) => {
  const response = axios.put(
    `${APIPaths?.getChecklist}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const updateActiveStatus = (payload: any) => {
  const response = axios.patch(
    `${APIPaths.getChecklist}${payload.id}/`,
    payload.body,
    APIConfig()
  );
  return response;
};

export const deleteChecklist = (id: string) => {
  const response = axios.delete(`${APIPaths?.getChecklist}${id}/`, APIConfig());
  return response;
};

export const createChecklist = (payload: any) => {
  const response = axios.post(APIPaths?.createChecklist, payload, APIConfig());
  return response;
};

export const addNewChecklist = (payload: any) => {
  const response = axios.post(APIPaths?.getChecklist, payload, APIConfig());
  return response;
};

export const getRequisitionById = async (id: string) => {
  const res = await fetch(`${APIPaths.getRequisition}${id}/`, APIConfig());
  return res.json();
};

export const getDraftById = async (id: string) => {
  const res = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return res.json();
};

export const getKDPDataPoints = async (id: string) => {
  const res = await fetch(
    `${APIPaths.draftBaseUrl}insights/${id}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchAssignee = async () => {
  const res = await fetch(`${APIPaths.draftBaseUrl}assignees/`, APIConfig());
  return res.json();
};

export const saveDraft = async (payload?: any) => {
  return axios.post(
    `${APIPaths.draftBaseUrl}bulk_optional_field/`,
    payload,
    APIConfig()
  );
};

export const uploadNewDocument = async (payload: any) => {
  return axios.post(`${APIPaths?.uploadDocUrl}`, payload, APIConfig());
};

export const upload_file_in_s3_bucket = ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append('file', file);

  const config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  axios.post(url, formData, config);
  return presignedPostData;
};

export const fetchTemplateById = async (id: string) => {
  const response = await fetch(`${APIPaths?.getTemplates}${id}/`, APIConfig());
  return response.json();
};

export const fetchExecutedContracts = async (payload: {
  id: string;
  page: number;
  page_size: number;
}) => {
  const response = await fetch(
    `${APIPaths?.getContractTemplates}?contract_type=${payload.id}&page=${payload.page}&page_size=${payload.page_size}`,
    APIConfig()
  );
  return response.json();
};

export const fetchEarlierDrafts = async (payload: any) => {
  const response = await fetch(
    `${APIPaths?.draftBaseUrl}get_latest/?filter_parameter=Group&filter_type=earlier_draft&contractType=${payload?.name}&page=${payload?.page}&page_size=${payload.page_size}`,
    APIConfig()
  );
  return response.json();
};

export const getS3PresignedUrl = async (fileInfo: any) => {
  const response = await axios.post(
    fileInfo.file_type === 'template' ||
      fileInfo.file_type === 'support_doc_template'
      ? APIPaths.getTemplatePresignedURL
      : APIPaths.getS3PresignedURL,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const getS3ReferencePresignedURL = async (payload: any) => {
  const response = await axios.post(
    APIPaths.getS3PresignedURL,
    payload,
    APIConfig()
  );
  return response;
};

export const referenceDocument = async (payload: any) => {
  const response = await axios.post(
    APIPaths.referenceDocument,
    payload,
    APIConfig()
  );
  return response;
};

export const fetchReferenceDocument = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.referenceDocument}?draft=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchOptionalFields = async (draft_uuid: string) => {
  const res = await fetch(
    `${APIPaths.getOptionalFields}${draft_uuid}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchGetFields = async (id: string) => {
  const res = await fetch(
    `${APIPaths.getFields}?contract_type=${id}`,
    APIConfig()
  );
  return res.json();
};

export const fetchKDPTagDeviations = async (id: string, type: string) => {
  const res = await fetch(
    `${APIPaths.getDeviations}?draft=${id}&deviation_type=${type}`,
    APIConfig()
  );
  return res.json();
};

export const fetchMergeTagDeviations = async (id: string) => {
  const res = await fetch(
    `${APIPaths.getMergeTagDeviations}${id}/`,
    APIConfig()
  );
  return res.json();
};

export const deleteSupportDocFromChecklist = (id: string) => {
  const payload = {
    deleted_status: true,
  };
  const response = axios.patch(
    `${APIPaths?.uploadDocUrl}${id}/`,
    payload,
    APIConfig()
  );
  return response;
};

export const getDraftApprovals = async (id: string, type: string) => {
  const res = await fetch(
    `${APIPaths.getApprovals}?draft=${id}&approval_type=${type}`,
    APIConfig()
  );
  return res.json();
};

export const addDraftApprovalComments = async (payload: any) => {
  return axios.post(APIPaths.approvalComments, payload, APIConfig());
};

export const deleteApprovalComment = async (id: string) => {
  return axios.delete(`${APIPaths.approvalComments}${id}/`, APIConfig());
};

export const addDraftApprovalBulkComments = async (payload: any) => {
  return axios.post(
    `${APIPaths.approvalComments}comments-bulk-update/`,
    payload,
    APIConfig()
  );
};

export const addDraftChecklistComments = async (payload: any) => {
  return axios.post(APIPaths.checklistComments, payload, APIConfig());
};

export const deleteChecklistComment = async (id: string) => {
  return axios.delete(`${APIPaths.checklistComments}${id}/`, APIConfig());
};

export const addDraftChecklistBulkComments = async (payload: any) => {
  return axios.post(
    `${APIPaths.checklistComments}comments-bulk-update/`,
    payload,
    APIConfig()
  );
};

export const updateDeviationApprovals = (id: string, payload: any) => {
  const response = axios.patch(
    `${APIPaths.requestApproval}${id}/`,
    payload,
    APIConfig()
  );
  return response;
};

export const draftDeviation = (payload: any) => {
  return axios
    .post(APIPaths.draft_deviation, payload, APIConfig())
    .catch((error) => {
      console.error('Error while create merge tag', error);
    });
};

export const updateDeviations = (id: string, payload: any) => {
  const response = axios.patch(
    `${APIPaths.draft_deviation}${id}/`,
    payload,
    APIConfig()
  );
  return response;
};

export const requestApproval = async (payload: any) => {
  return axios.post(APIPaths?.draft_approval, payload, APIConfig());
};

export const deleteCustomDeviation = async (id: string) => {
  const response = axios.delete(
    `${APIPaths?.draft_deviation}${id}/`,
    APIConfig()
  );
  return response;
};

export const requestAccess = async (payload: any) => {
  return axios.post(
    `${APIPaths.draftBaseUrl}draft-lock/`,
    payload,
    APIConfig()
  );
};

export const deleteCompleteDraftField = async (id: string) => {
  return await authApi.delete(`stylus/draft/optional_field/delete/${id}/`);
};

export const send_Email = async (payload: any) => {
  return await authApi.post('stylus/draft/send-email/', payload);
};

export const externalUsers = async (payload: any) => {
  return axios.post(`${APIPaths.externalUsers}`, payload, APIConfig());
};

export const editExternalUsers = async (payload: any) => {
  return authApi.patch(`users/${payload.id}/`, payload.body);
};

export const deleteExternalUser = async (payload: any) => {
  return authApi.patch(`users/${payload?.id}/`, payload?.body);
};

export const fetchExternalUsersList = async () => {
  const res = await fetch(`${APIPaths.externalUsersList}`, APIConfig());
  return res.json();
};

export const assigneesList = async (payload?: any) => {
  return axios.post(
    `${APIPaths.draftBaseUrl}assignees-list/`,
    payload,
    APIConfig()
  );
};

export const fetchPolicyContractTypes = async () => {
  return await authApi.get('admin/contracttype/?has_policy=true');
};

export const removeChecklistAssignment = async (payload: any) => {
  return await authApi.post(
    'stylus/draft_checklist/remove-checklist-assignment/',
    payload
  );
};
