import { authApi } from '../../Services/authApi';

export default class AggregatesAPI {
  public static searchFilter(url: string, payload: any) {
    return authApi.post(url, payload);
  }
  public static filterResult(url: string, object: any) {
    return authApi.post(url, object);
  }
}
