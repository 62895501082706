import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  deleteExternalUser,
  editExternalUsers,
  externalUsers,
} from '../../../Services/Draft';
import { removeAnnotation } from '../Helper';

interface Props {
  signatoriesData: any[];
  userTypes: any[];
  user_type: any;
  onSubmit: (data: any) => void;
  handleUserType: VoidFunction;
  control: any;
  handleSubmit: any;
  instance: any;
  externalUsersData: any;
  isLoading: boolean;
}

const AddSignatories: React.FC<Props> = ({
  signatoriesData,
  userTypes,
  user_type,
  onSubmit,
  handleUserType,
  control,
  handleSubmit,
  instance,
  externalUsersData,
  isLoading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { watch, reset, setValue, resetField } = useFormContext();
  const [showExternalFields, setShowExternalFields] = useState(false);
  const [editExternalUser, setEditExternalUser] = useState<any>(null);

  const name = watch('name') || '';
  const email = watch('email') || '';
  const selectExternalUser = watch('select_external_user' || '');

  const handleClear = async () => {
    handleUserType();
    reset();
    await removeAnnotation(
      instance,
      user_type === 'external' ? selectExternalUser : ''
    );
  };

  useEffect(() => {
    if (selectExternalUser) {
      setEditExternalUser(null);
      resetField('name');
      resetField('email');
    }
  }, [selectExternalUser]);

  const { mutate: externalUsersMutation } = useMutation({
    mutationKey: ['external_users', editExternalUser],
    mutationFn: editExternalUser ? editExternalUsers : externalUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External fields saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setEditExternalUser(null);
      setShowExternalFields(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to save external fields!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteExternalUserMutation } = useMutation({
    mutationKey: ['delete-external-user'],
    mutationFn: deleteExternalUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External user deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddNewClick = useCallback(() => {
    setEditExternalUser(null);
    resetField('email');
    resetField('name');
    setShowExternalFields((prev) => !prev);
  }, []);

  const handleNewFieldsSave = () => {
    let payload: any = [
      {
        user_name: name,
        email: email,
      },
    ];
    if (editExternalUser) {
      payload = {
        id: editExternalUser.id,
        body: { user_email: email },
      };
    }
    externalUsersMutation(payload);
  };

  const sortedExternalUsers = useMemo(() => {
    return externalUsersData?.sort((a: any, b: any) =>
      a?.username.localeCompare(b?.username)
    );
  }, [externalUsersData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1}
        sx={{ backgroundColor: '#88305f1f' }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Add signatories
        </Typography>

        <RadioButtonGroup
          name="user_type"
          control={control}
          row
          required
          options={userTypes}
          valueKey="value"
          onChange={handleUserType}
        />

        {user_type === 'external' && (
          <Stack spacing={2}>
            <RISelectComponent
              label="Select External Signatory"
              name="select_external_user"
              control={control}
              options={sortedExternalUsers}
              required
              renderAction={(value: any) => (
                <Stack direction="row">
                  {/* <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowExternalFields(false);
                      setTimeout(() => {
                        setEditExternalUser(value);
                        setValue('name', value.username);
                        setValue('email', value.email);
                        resetField('select_external_user');
                      }, 0);
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton> */}
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        resetField('select_external_user');
                      }, 0);
                      const payload = {
                        id: value.id,
                        body: {
                          is_active: false,
                        },
                      };
                      deleteExternalUserMutation(payload);
                      setTimeout(() => {
                        resetField('select_external_user');
                      }, 0);
                    }}
                    color="error"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
              )}
            />
            {!editExternalUser && (
              <Button
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  gap: '6px',
                }}
                onClick={handleAddNewClick}
              >
                <AddCircleOutlineRoundedIcon fontSize="small" /> Add New
              </Button>
            )}
            {(showExternalFields || editExternalUser) && (
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {showExternalFields && (
                    <ControlledTextField
                      name="name"
                      control={control}
                      label="Name"
                      fullWidth
                      required
                    />
                  )}
                  <ControlledTextField
                    name="email"
                    control={control}
                    label="Email Address"
                    fullWidth
                    type="email"
                    required
                  />
                </Stack>
                <Stack direction="row" justifyContent="end">
                  {editExternalUser && (
                    <Button
                      variant="text"
                      sx={{ width: 'fit-content' }}
                      onClick={() => setEditExternalUser(null)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                    onClick={handleNewFieldsSave}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}

        {user_type === 'internal' && (
          <RISelectComponent
            name="name"
            control={control}
            label="Select Signatory"
            options={signatoriesData}
            required
          />
        )}
        <Stack justifyContent="end" direction="row" paddingTop="15px">
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Add signatory
          </LoadingButton>
          <Button onClick={handleClear} variant="outlined">
            Clear
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddSignatories;
