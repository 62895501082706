import {
  ADDFILTER_SUCCESS,
  AggregateActions,
  EXPANDAGGREGATE,
  FILTERRESULT,
  FILTERRESULT_FAILURE,
  FILTERRESULT_SUCCESS,
  INITFILTERS,
  REMOVEFILTERS,
  SAVEAPPLIEDFILTERS,
  SEARCHFILTER,
  SEARCHFILTER_SUCCESS,
} from '../Actions/AggregatesDef';
import FilterModule, {
  defaultFilterModuleState,
} from '../State/AggregatesState';

export default function aggregatesReducer(
  state: FilterModule = defaultFilterModuleState(),
  action: AggregateActions
): FilterModule {
  switch (action.type) {
    case FILTERRESULT: {
      if (action.payload.isLoader) {
        return {
          ...state,
          filterLoader: true,
          isFilterResultEmpty: false,
          appliedFilters: action.payload.appliedFilters,
        };
      } else {
        return {
          ...state,
          scrollBottomLoader: true,
          isFilterResultEmpty: false,
          appliedFilters: action.payload.appliedFilters,
        };
      }
    }
    case FILTERRESULT_SUCCESS: {
      // isFilterResutEmpty to check whether the filter application yielded empty result so as to show error message on search result page. set this to false on every application
      return {
        ...state,
        sort: action.payload.sort,
        filterLoader: false,
        scrollBottomLoader: false,
        isFilterResultEmpty: action.payload.isResultEmpty,
        initFilter: false,
      };
    }
    case FILTERRESULT_FAILURE: {
      return { ...state, filterLoader: false };
    }
    case ADDFILTER_SUCCESS: {
      return {
        ...state,
        appliedFilters: { ...action.payload.filters },
        initFilter: false,
      };
    }
    case REMOVEFILTERS: {
      return { ...state, appliedFilters: [] };
    }
    case EXPANDAGGREGATE: {
      const name = action.payload.name;
      if (name === 'riverus') {
        return { ...state, expandList: [] };
      } else {
        const list = JSON.parse(JSON.stringify(state.expandList)) as string[];
        if (list.indexOf(name) > -1) {
          list.splice(list.indexOf(name), 1);
        } else {
          list.push(name);
        }
        return { ...state, expandList: list };
      }
    }
    case INITFILTERS: {
      return {
        ...state,
        sort: action.payload.sort,
        appliedFilters: action.payload.appliedFilters.slice(0),
        initFilter: true,
      };
    }
    case SEARCHFILTER: {
      return { ...state, searchFilterLoader: true };
    }
    case SEARCHFILTER_SUCCESS: {
      return {
        ...state,
        filterResult: action.payload.filterResult,
        searchFilterLoader: false,
      };
    }
    case SAVEAPPLIEDFILTERS: {
      return { ...state, savedFilterArray: action.payload.savedFilterArray };
    }
    default:
      return state;
  }
}
