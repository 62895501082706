import { SearchResult } from '../State/SearchState';

export const SEARCH = 'SEARCH';
export type SEARCH = typeof SEARCH;
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export type SEARCH_SUCCESS = typeof SEARCH_SUCCESS;
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export type SEARCH_FAILURE = typeof SEARCH_FAILURE;

export const SEARCH_WITH_FILTER = 'SEARCH_WITH_FILTER';
export type SEARCH_WITH_FILTER = typeof SEARCH_WITH_FILTER;

export interface Search {
  type: SEARCH;
  payload: {
    searchTerm: string;
    sortBy: string;
    pageSize: number;
    filterType: string;
  };
}
export interface SearchSuccess {
  type: SEARCH_SUCCESS;
  payload: {
    searchResult: SearchResult[];
  };
}
export interface SearchFailure {
  type: SEARCH_FAILURE;
}
export interface SearchWithFilter {
  type: SEARCH_WITH_FILTER;
  payload: any;
}

export type SearchPageAction =
  | Search
  | SearchSuccess
  | SearchFailure
  | SearchWithFilter;
