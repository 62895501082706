import { connect } from 'react-redux';

import SearchBarActionGenerator from '../../UniversalComponents/SearchBar/Action/actionGen';
import { StoreTree } from '../../Utils/MainReducer/mainReducer';
import App from '../Component/AppFunctional';

export function mapStateToProps(appState: StoreTree) {
  return {
    visibility: appState.searchBar.visibility,
  };
}
export function mapDispatchToProps(dispatch: any) {
  return {
    toggleSearchBarVisibility: (visibility: boolean) =>
      dispatch(SearchBarActionGenerator.toggleVisibility(visibility)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
