import React from 'react';

import { ChipProps, Stack } from '@mui/material';

import AutocompleteElement from '../RiverusUI/Components/AutoCompleteElement';
import { FormInputProps } from '../RiverusUI/Interfaces/FormInputProps';

interface IProps extends FormInputProps {
  isMultiselect?: boolean;
  name: string;
  control: any;
  renderCustomComponent?: (value: string, props?: ChipProps) => React.ReactNode;
  loading?: boolean;
  showCheckbox?: boolean;
  labelKey?: string;
  valueKey?: string;
}

const FilterInput: React.FC<IProps> = ({
  isMultiselect = false,
  name,
  control,
  renderCustomComponent,
  loading = false,
  showCheckbox = true,
  label,
  options,
  ...props
}) => {
  return (
    <AutocompleteElement
      label={label}
      name={name}
      multiple={isMultiselect}
      options={options || []}
      matchId
      showCheckbox={showCheckbox}
      control={control}
      loading={loading}
      autocompleteProps={{
        renderTags: (value, getTagProps) =>
          renderCustomComponent ? (
            <Stack
              direction="row"
              sx={{
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              gap={1}
            >
              {value.map((option: any, index) =>
                renderCustomComponent(option, { ...getTagProps({ index }) })
              )}
            </Stack>
          ) : null,
      }}
      {...props}
    />
  );
};

export default FilterInput;
