import { connect } from 'react-redux';

import { StoreTree } from '../../../Utils/MainReducer/mainReducer';
import HeimdallActionGen from '../../HeimdallChild/Actions/actionGen';
import SearchBarActionGenerator from '../../SearchBar/Action/actionGen';
import Toolbar from '../Components/Toolbar';

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    history: ownProps.history,
    visibility: appState.searchBar.visibility,
    platform: appState.heimdall.platform,
  };
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    toggleSearchBarVisibility: (visibility: boolean) =>
      dispatch(SearchBarActionGenerator.toggleVisibility(visibility)),
    gotoUmbrella: () => dispatch(HeimdallActionGen.gotoUmbrella()),
    gotoStyllus: () => dispatch(HeimdallActionGen.gotoStyllus()),
    gotoAdmin: () => dispatch(HeimdallActionGen.gotoAdmin()),
    gotoReminder: () => dispatch(HeimdallActionGen.gotoReminder()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
