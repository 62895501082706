import { combineReducers } from 'redux';

import aggregatesReducer from '../../Aggregates/Reducer/AggregatesRed';
import FilterModule from '../../Aggregates/State/AggregatesState';
import analysisReducer from '../../Analysis/Reducer/red';
import AnalysisState from '../../Analysis/State/analysisState';
import appReducer from '../../App/Reducer/appReducer';
import AppState from '../../App/State/appState';
import ClauseComponentReducer from '../../DocumentView/Component/ClauseComponent/Reducer/clauseComponentRed';
import ClauseComponentState from '../../DocumentView/Component/ClauseComponent/State/clauseComponentState';
import documentReducer from '../../DocumentView/Reducer/documentRed';
import DocumentState from '../../DocumentView/State/documentState';
import searchReducer from '../../Search/Reducer/SearchRed';
import SearchState from '../../Search/State/SearchState';
import { TemplateState } from '../../Templates/State/templateState';
import HeimdallReducer from '../../UniversalComponents/HeimdallChild/Reducer/heimdallReducer';
import HeimdallState from '../../UniversalComponents/HeimdallChild/State/heimdallState';
import SearchBarReducer from '../../UniversalComponents/SearchBar/Reducer/red';
import SearchBarState from '../../UniversalComponents/SearchBar/State/searchBarState';

export interface StoreTree {
  [x: string]: any;
  heimdall: HeimdallState;
  aggregates: FilterModule;
  document: DocumentState;
  app: AppState;
  searchBar: SearchBarState;
  search: SearchState;
  analysis: AnalysisState;
  templateLibrary: TemplateState;
  clauseComponent: ClauseComponentState;
}

export const mainReducer = combineReducers({
  heimdall: HeimdallReducer,
  aggregates: aggregatesReducer,
  document: documentReducer,
  app: appReducer,
  searchBar: SearchBarReducer,
  search: searchReducer,
  analysis: analysisReducer,
  clauseComponent: ClauseComponentReducer,
});
