import React, { useEffect, useMemo, useState } from 'react';

import { AddOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';
import { enqueueSnackbar } from 'notistack';
import { useFieldArray, useForm } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import {
  fetchContractingParty,
  updateContractingParty,
  updateEsClauseDataByType,
} from '../../../Services/DocumentLibrary';
import { getEditedPayload } from '../ClauseComponent/utils/ClauseTypeUtils';

interface Props {
  contractId: string;
}

const ContractingParties: React.FC<Props> = ({ contractId }) => {
  const [editContractParties, setEditContractParties] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const { handleSubmit, control, resetField } = useForm();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'contracting_parties',
  });

  const { data: contractData } = useQuery({
    queryKey: ['get_contract_party', contractId],
    queryFn: () => fetchContractingParty(contractId),
    enabled: !!contractId,
  });

  const { mutate: updateContractParties } = useMutation({
    mutationKey: ['update_contracting_parties'],
    mutationFn: async (payload: any) => updateContractingParty(payload),
    onSuccess: () => {
      enqueueSnackbar('contracting parties updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['get_contract_party'] });
      replace({});
      setEditContractParties(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to update contracting parties!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: updateESContractingParties } = useMutation({
    mutationKey: ['update_es'],
    mutationFn: (payload: any) => updateEsClauseDataByType(payload),
  });

  const contracting_parties_clause = useMemo(() => {
    let changeList;
    if (contractData) {
      const diff = JSON.parse(JSON.stringify(contractData.edited_content));

      const orig = JSON.parse(JSON.stringify(contractData.raw_content));
      if (diff) {
        const unFlattenChanges = changesets.unflattenChanges(diff.update);
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(
              JSON.parse(JSON.stringify(orig)),
              unFlattenChanges
            )
          )
        );
      } else {
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
          )
        );
      }
      resetField('contracting_parties');
      return changeList.contracting_parties;
    }
    return changeList;
  }, [contractData, resetField]);

  const getPartyNames = contracting_parties_clause?.map(
    (item: any) => item?.party || item?.partyName
  );

  useEffect(() => {
    if (contracting_parties_clause?.length > 0) {
      contracting_parties_clause?.map((partyItem: any) => {
        append({
          party: partyItem.partyName ? partyItem?.partyName : partyItem?.party,
          ...partyItem,
        });
        return partyItem;
      });
    }
  }, [append, contracting_parties_clause]);

  const onSubmitContractParty = (data: any) => {
    const modifiedParties = data?.contracting_parties?.filter((party: any) => {
      if (party?.party) {
        delete party?.partyName;
        if (party?.partyType === null || party?.partyType === undefined) {
          delete party.partyType;
        }
      }
      return party;
    });

    const newContractPartyData = {
      contracting_parties: modifiedParties,
    };

    const diff = changesets.diff(
      contractData?.raw_content,
      newContractPartyData,
      {
        children: '$index',
      }
    );

    if (diff.length > -1) {
      const flatChanges = changesets.flattenChangeset(diff);
      const editedPayload = getEditedPayload(flatChanges);
      const payload = {
        id: contractId,
        body: {
          edited_content: editedPayload?.edited_content,
          copy_edited_content: newContractPartyData,
        },
      };
      updateContractParties(payload);
      updateESContractingParties({
        index: 'contracting_parties',
        contract_id: contractId,
        document: newContractPartyData,
      });
    }
  };

  return (
    <React.Fragment>
      {editContractParties ? (
        <Box
          style={{
            background: '#FFECF1',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <form onSubmit={handleSubmit(onSubmitContractParty)}>
            <Stack spacing={2} width="100%">
              <Typography fontWeight={600}>Edit Contract Parties</Typography>
              {fields.map((field: any, index: number) => (
                <Grid container justifyContent="space-between" key={index}>
                  <Grid item sm={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography>Contract Party {index + 1}</Typography>
                      <Button
                        variant="text"
                        startIcon={<DeleteSweepOutlinedIcon />}
                        onClick={() => {
                          remove(index); // Remove the item at the specified index
                        }}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item sm={12}>
                    <Stack spacing={2} key={field.id}>
                      <ControlledTextField
                        name={`contracting_parties[${index}].party`}
                        control={control}
                        label="Party Name"
                        fullWidth
                        disabled={getPartyNames?.includes(field?.party)}
                      />
                      <ControlledTextField
                        name={`contracting_parties[${index}].partyType`}
                        control={control}
                        label="Party Type"
                        fullWidth
                        disabled={getPartyNames?.includes(field?.party)}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              ))}
              <Stack direction="row">
                <Button
                  variant="text"
                  startIcon={<AddOutlined />}
                  onClick={() => {
                    append({
                      party: '',
                      partyType: '',
                      mode: 'manual',
                      id: '',
                    });
                  }}
                >
                  Add a contracting party
                </Button>
              </Stack>
              <Stack direction="row">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  startIcon={<CheckIcon />}
                >
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  onClick={() => setEditContractParties(false)}
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      ) : (
        <Box
          style={{
            background: '#FFECF1',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <Stack width="100%" justifyContent="space-between" direction="row">
            <Typography fontWeight={600}>Contract Parties</Typography>
            <Button
              variant="text"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              style={{ padding: 0 }}
              onClick={() => setEditContractParties(true)}
            >
              Edit
            </Button>
          </Stack>
          {contracting_parties_clause?.map((item: any, index: number) => (
            <Typography fontSize="13px" key={index}>
              {index + 1}). {item?.partyName ? item?.partyName : item?.party}
              <br />
              {item?.partyType && (
                <span style={{ marginLeft: '1.2rem' }}>{item?.partyType}</span>
              )}
            </Typography>
          ))}
        </Box>
      )}
    </React.Fragment>
  );
};

export default ContractingParties;
