import {
  SEARCH,
  Search,
  SEARCH_FAILURE,
  SEARCH_SUCCESS,
  SEARCH_WITH_FILTER,
  SearchFailure,
  SearchSuccess,
  SearchWithFilter,
} from './SearchDef';
import { SearchResult } from '../State/SearchState';

export default class SearchActionGen {
  public static search(
    searchTerm: string,
    sortBy: string,
    pageSize: number,
    filterType: string
  ): Search {
    return {
      type: SEARCH,
      payload: {
        searchTerm: searchTerm,
        sortBy: sortBy,
        pageSize: pageSize,
        filterType: filterType,
      },
    };
  }
  public static searchSuccess(searchResult: SearchResult[]): SearchSuccess {
    return {
      type: SEARCH_SUCCESS,
      payload: {
        searchResult: searchResult,
      },
    };
  }
  public static searchFailure(): SearchFailure {
    return {
      type: SEARCH_FAILURE,
    };
  }
  public static searchWithFilter(payload: any): SearchWithFilter {
    return {
      type: SEARCH_WITH_FILTER,
      payload: payload,
    };
  }
}
