import { connect } from 'react-redux';

import AppActionGenerator from '../../App/Actions/actionGen';
import ApprovalsComponent from '../Component/ApprovalsComponent';

export function mapStateToProps() {
  return {};
}

export function mapDispatchToProps(dispatch: any) {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApprovalsComponent);
