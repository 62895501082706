import React, { FC, useCallback, useMemo } from 'react';

import { Button, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  addAdminContract,
  addDictionaryContractType,
  fetchAdminContractList,
} from '../../../Services/dictionary';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const CreateContractForm: FC<IProps> = (props) => {
  const { open, onClose } = props;
  const methods = useForm();
  const { handleSubmit, control, watch, reset } = methods;

  const queryClient = useQueryClient();

  const contract = watch('contract');

  const { data: contractOption, isLoading: contractLoading } = useQuery({
    queryKey: ['contract-list'],
    queryFn: fetchAdminContractList,
  });

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const { mutate: createContractMutation } = useMutation({
    mutationKey: ['create-contract'],
    mutationFn: addDictionaryContractType,
    onSuccess: () => {
      handleCloseModal();
      queryClient.invalidateQueries({ queryKey: ['admin-data-dictionary'] });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.__all__?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create contract type'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: addContractMutation } = useMutation({
    mutationKey: ['add-contract'],
    mutationFn: addAdminContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contract-list'] });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.name?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create contract type'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const selectedContract = useMemo(() => {
    if (contract) {
      const selected = contractOption.filter(
        (data: any) => data.id === contract
      );
      return selected[0];
    }
    return null;
  }, [contract, contractOption]);

  const onSubmit = useCallback(
    (data: any) => {
      createContractMutation({
        name: selectedContract?.name,
        displayName: data?.displayName,
        creation_type: selectedContract?.creation_type,
      });
    },
    [createContractMutation, selectedContract]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Add Contract Type"
      open={open}
      handleClose={handleCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '400px', padding: '30px 10px' }} gap={2}>
            <RISelectComponent
              required
              name="contract"
              options={contractOption}
              control={control}
              loading={contractLoading}
              label="Select Contract Type"
              canCreateNew
              addNewValue={(value) => addContractMutation({ name: value })}
            />
            <ControlledTextField
              required
              name="displayName"
              control={control}
              placeholder="Display Name"
            />
            <Button
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateContractForm;
