/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux';

import { StoreTree } from '../../../Utils/MainReducer/mainReducer';
import SearchBarActionGenerator from '../../SearchBar/Action/actionGen';
import MobileMenu from '../Component/mobileMenu';

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {};
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    toggleVisibility: (display: boolean) =>
      dispatch(SearchBarActionGenerator.toggleVisibility(display)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
