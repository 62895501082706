import React, { useEffect, useMemo, useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Stack, Tab, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import CustomDeviation from './CustomDeviation';
import Policies from './Policies';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import { fetchKDPTagDeviations } from '../../../Services/Draft';
import { loadingOfInsightList } from '../ViewDraft';

interface Props {
  draftData: any;
  instance: any;
  handleOpenClauseLibraryDrawer: VoidFunction;
}

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  paddingX: '10px',
  marginTop: '15px',
};

const DeviationTab: React.FC<Props> = ({
  draftData,
  instance,
  handleOpenClauseLibraryDrawer,
}) => {
  const draftId =
    draftData?.createFrom === 'template' && draftData?.version === 1
      ? draftData?.draftID
      : draftData?.id;

  const [tabValue, setTabValue] = useState<string>('');
  const [deviationType, setDeviationType] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('deviant');
  const [selectedStatus, setSelectedStatus] = useState('default');
  const [filteredDeviation, setFilteredDeviation] = useState<any[]>([]);

  const { data: deviationsList, isLoading: isLoadingDeviations } = useQuery({
    queryKey: ['fetch_Kdp_deviations', draftId, deviationType],
    queryFn: async () => {
      const id = deviationType === 'kdp' ? draftData?.id : draftId;
      const response = await fetchKDPTagDeviations(id, deviationType);
      return response?.results;
    },
    enabled: !!draftId && !!deviationType,
  });

  const { data: customDeviationList } = useQuery({
    queryKey: ['fetch_custom_deviations', draftData?.id],
    queryFn: () => fetchKDPTagDeviations(draftData?.id, 'custom'),
    select: (response: any) => response?.results,
    enabled: !!draftData?.id,
  });

  useEffect(() => {
    if (deviationsList?.length > 0) {
      let filteredData = [];
      if (
        selectedType === 'show_all' &&
        (selectedStatus === 'default' || selectedStatus === '')
      ) {
        filteredData = deviationsList;
      } else if (selectedType === 'show_all') {
        filteredData = deviationsList?.filter(
          (item: any) => item?.approval_status === selectedStatus
        );
      } else if (selectedStatus === 'default' || selectedStatus === '') {
        filteredData = deviationsList?.filter(
          (item: any) => item?.status === selectedType
        );
      } else {
        filteredData = deviationsList?.filter((item: any) => {
          if (
            item?.status === selectedType &&
            item?.approval_status === selectedStatus
          ) {
            return item;
          }
        });
      }
      setFilteredDeviation(filteredData);
    }
  }, [selectedStatus, selectedType, deviationsList]);

  useEffect(() => {
    if (draftData?.createFrom === 'template' && draftData?.version === 1) {
      setDeviationType('merge_tag');
    } else {
      setDeviationType('kdp');
    }
  }, [draftData]);

  useEffect(() => {
    if (
      (draftData?.createFrom === 'template' && draftData?.version === 1) ||
      deviationsList?.length === 0
    ) {
      setTabValue('custom');
    } else {
      setTabValue('policies');
    }
  }, [deviationsList, draftData]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const user_data = useMemo(() => getSessionStorage('user_profile'), []);

  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  const policyApproversIds = useMemo(() => {
    const ids = deviationsList
      ?.filter((deviation: any) => deviation?.approval_status)
      .flatMap(
        (deviation: any) =>
          deviation?.condition?.approvers?.map(
            (approver: any) => approver?.id
          ) || []
      )
      .filter((id: any) => id);

    return Array.from(new Set(ids));
  }, [deviationsList]);

  const customApproversIds = useMemo(() => {
    const ids = customDeviationList
      ?.flatMap(
        (deviation: any) => deviation?.custom_datapoint?.[0]?.approvers || []
      )
      .filter((id: any) => id !== undefined && id !== null);

    return Array.from(new Set(ids));
  }, [customDeviationList]);

  const combinedApproversIds = useMemo(() => {
    const combinedIds = [...policyApproversIds, ...customApproversIds];
    return Array.from(new Set(combinedIds));
  }, [policyApproversIds, customApproversIds, customDeviationList]);

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        style={innerTabStyle}
      >
        {!(
          (draftData?.createFrom === 'template' && draftData?.version === 1) ||
          deviationsList?.length === 0
        ) && <Tab label="Policies" value="policies" />}

        <Tab label="Custom" value="custom" />
      </TabList>

      <Stack
        spacing={1}
        sx={{
          height: '70vh',
          overflowY: 'auto',
          backgroundColor: 'riTertiary.50',
          borderRadius: '24px',
          mt: '8px',
        }}
      >
        <TabPanel value="policies" sx={{ padding: '15px' }}>
          {isLoadingDeviations ? (
            <Box px={2}>
              <ListSkeleton />
            </Box>
          ) : deviationsList?.length === 0 ? (
            <Typography>No issue for this draft</Typography>
          ) : loadingOfInsightList && deviationType === 'kdp' ? (
            <CircularProgress />
          ) : (
            <Policies
              instance={instance}
              handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
              userIsOwner={userIsOwner}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              filteredDeviation={filteredDeviation}
              combinedApproversIds={combinedApproversIds}
            />
          )}
        </TabPanel>
        <TabPanel value="custom" sx={{ padding: '15px' }}>
          <CustomDeviation
            instance={instance}
            handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
            draftId={draftData?.id}
            userIsOwner={userIsOwner}
            customDeviationList={customDeviationList}
            combinedApproversIds={combinedApproversIds}
            deviationType={deviationType}
          />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default DeviationTab;
