import {
  ADDFILTER,
  AddFilter,
  ADDFILTER_SUCCESS,
  AddFilterSuccess,
  DELETEFILTER,
  DeleteFilter,
  EXPANDAGGREGATE,
  ExpandAggregate,
  FILTERRESULT,
  FilterResult,
  FILTERRESULT_FAILURE,
  FILTERRESULT_SUCCESS,
  FilterResultFailure,
  FilterResultSuccess,
  InitFilter,
  INITFILTERS,
  REMOVEFILTERS,
  RemoveFilters,
  SaveAppliedFilters,
  SAVEAPPLIEDFILTERS,
  SEARCH_CLAUSES,
  SearchClauses,
  SEARCHFILTER,
  SearchFilter,
  SEARCHFILTER_SUCCESS,
  SearchFilterSuccess,
} from './AggregatesDef';
import { AggregateState, AppliedFilters } from '../State/AggregatesState';

export default class AggregatesActionGenerator {
  public static searchFilter(
    value: string,
    level: number,
    page: string,
    sort: string,
    type: string,
    filter: AppliedFilters[],
    fileId: string[],
    aggregateType: string
  ): SearchFilter {
    return {
      type: SEARCHFILTER,
      payload: {
        value: value,
        level: level,
        page: page,
        sort: sort,
        type: type,
        filter: filter,
        fileId: fileId,
        aggregateType: aggregateType,
      },
    };
  }
  public static searchFilterSuccess(
    filterResult: AggregateState[]
  ): SearchFilterSuccess {
    return {
      type: SEARCHFILTER_SUCCESS,
      payload: {
        filterResult: filterResult,
      },
    };
  }
  public static addFilter(
    clauseType: string,
    value: string,
    isAdd: boolean,
    currentAppliedFilter: any
  ): AddFilter {
    return {
      type: ADDFILTER,
      payload: {
        clauseType: clauseType,
        value: value,
        isAdd: isAdd,
        currentAppliedFilter: currentAppliedFilter,
      },
    };
  }
  public static addFilterSuccess(filters: any): AddFilterSuccess {
    return {
      type: ADDFILTER_SUCCESS,
      payload: {
        filters: filters,
      },
    };
  }
  public static expandAggregate(name: string): ExpandAggregate {
    return {
      type: EXPANDAGGREGATE,
      payload: {
        name: name,
      },
    };
  }
  public static deleteFilter(
    filters: AppliedFilters[],
    path: string,
    sort: string,
    page: string,
    window: number,
    pageNumber: number,
    filterType: string
  ): DeleteFilter {
    return {
      type: DELETEFILTER,
      payload: {
        filters: filters,
        path: path,
        sort: sort,
        page: page,
        window: window,
        pageNumber: pageNumber,
        filterType: filterType,
      },
    };
  }
  public static filterResult(
    filters: AppliedFilters[],
    sort: string,
    window: number,
    isLoader: boolean,
    setToDefault: boolean,
    filterType: string
  ): FilterResult {
    return {
      type: FILTERRESULT,
      payload: {
        appliedFilters: filters,
        sort: sort,
        window: window,
        isLoader: isLoader,
        setToDefault: setToDefault,
        filterType: filterType,
      },
    };
  }
  public static filterResultSuccess(
    sort: string,
    isResultEmpty: boolean,
    setToDefault: boolean
  ): FilterResultSuccess {
    return {
      type: FILTERRESULT_SUCCESS,
      payload: {
        sort: sort,
        isResultEmpty: isResultEmpty,
        setToDefault: setToDefault,
      },
    };
  }
  public static filterResultFailure(): FilterResultFailure {
    return {
      type: FILTERRESULT_FAILURE,
    };
  }
  public static InitFilter(
    appliedFilters: AppliedFilters[],
    sort: string
  ): InitFilter {
    return {
      type: INITFILTERS,
      payload: {
        appliedFilters: appliedFilters,
        sort: sort,
      },
    };
  }
  public static removeFilters(): RemoveFilters {
    return {
      type: REMOVEFILTERS,
    };
  }
  public static saveAppliedFilters(
    savedFilterArray: string[]
  ): SaveAppliedFilters {
    return {
      type: SAVEAPPLIEDFILTERS,
      payload: {
        savedFilterArray: savedFilterArray,
      },
    };
  }

  public static searchClauses(
    results: any[],
    searchTerm: string
  ): SearchClauses {
    return {
      type: SEARCH_CLAUSES,
      payload: {
        results: results,
        searchTerm: searchTerm,
      },
    };
  }
}
