import React, { useEffect, useMemo, useRef, useState } from 'react';

import WebViewer from '@pdftron/webviewer';

import { getTodayDate } from './Helper';
import {
  getLocalStorage,
  getSessionStorage,
} from '../../Authentication/Actions/authentication';
import { draftStatus } from '../State/DraftState';

const DocxViewerComponent = (props: any) => {
  const user_data = useMemo(() => getSessionStorage('user_profile'), []);
  const external_signatory_id = useMemo(
    () => getLocalStorage('external_signatory_id'),
    []
  );

  const documentContainer = useRef<HTMLDivElement>(null);
  const {
    fileLink,
    setInstance,
    dragOver,
    setAddedSignatureFields,
    drop,
    draftDetails,
    instance,
    isExternal,
    setDocumentLoading,
    internalSignatoryId,
  } = props;

  const [signatureAnnot, setSignatureAnnot] = useState<any | null>();

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      setDocumentLoading?.(true);

      const id = isExternal ? external_signatory_id : internalSignatoryId;

      const userIsSignatory = draftDetails?.signatories?.find(
        (signatory: any) =>
          signatory?.signatory_id === internalSignatoryId ||
          signatory?.signatory_id === external_signatory_id ||
          signatory?.email === user_data?.email
      );

      WebViewer.WebComponent(
        {
          path: '/webviewer',
          fullAPI: true,
          initialDoc: fileLink,
          css: '/styles/webviewer.css',
          disabledElements: [
            'header',
            'toolsHeader',
            'linkButton',
            'annotationCommentButton',
            'contextMenuPopup',
          ],
          licenseKey:
            'Riverus Technology Solutions Pvt. Ltd (riverus.in):OEM:Riverus::B+:AMS(20240530):A65548BC0477C80A0360B13AB9C2527160610FEBB9361A37E36C05DA5CF50ED654BA31F5C7',
        },
        documentContainer.current
      ).then((instance) => {
        const { UI, Core } = instance;
        setInstance(instance);
        const { documentViewer, Annotations, annotationManager } = Core;

        if (
          userIsSignatory &&
          !userIsSignatory?.signed_date &&
          draftDetails?.signature_method === 'Stylus (Riverus)'
        ) {
          const capitalize = (str: string) =>
            str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

          if (userIsSignatory?.user_type === 'external') {
            const name = capitalize(userIsSignatory?.name);
            instance.Core.annotationManager.setCurrentUser(name);
          } else {
            const firstName = capitalize(
              userIsSignatory?.first_name || userIsSignatory?.name
            );
            const lastName = capitalize(userIsSignatory?.last_name);

            instance.Core.annotationManager.setCurrentUser(
              `${firstName} ${lastName}`
            );
          }
        }

        instance.UI.annotationPopup.update([{ type: 'ac' }]);

        const callback = () => {
          // unsubscribe immediatly after invoking
          documentViewer.removeEventListener('documentLoaded', callback);
        };

        documentViewer.addEventListener('documentLoaded', async () => {
          const FitMode = instance.UI.FitMode;
          instance.UI.setFitMode(FitMode.FitWidth);
          setDocumentLoading?.(false);
          callback();
        });

        const doc = documentContainer.current;
        if (doc) {
          doc.addEventListener('dragover', dragOver);
          doc.addEventListener('drop', (e: any) => {
            drop(e, documentViewer);
          });
        }

        Annotations.setCustomCreateSignHereElementHandler(function (
          tool,
          { originalCreateSignHereElement }
        ) {
          const signHereElement = originalCreateSignHereElement(tool);
          signHereElement.innerHTML = 'Click Here To Sign';
          return signHereElement;
        });

        annotationManager.addEventListener(
          'annotationChanged',
          (annotations, action, { imported }) => {
            annotations.forEach((annot: any) => {
              annot.NoRotate = false;
            });

            if (draftDetails.status === draftStatus.SIGNATURE_PENDING) {
              annotations.forEach((annot: any) => {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                annot.ReadOnly = true;
              });
            }

            annotations.forEach((annot: any) => {
              if (annot.Subject?.toLowerCase() === 'signature') {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                annot.setCustomData('user', '');
                if (!annot.getCustomData('signedDate')) {
                  annot.setCustomData('signedDate', getTodayDate());
                  setSignatureAnnot(annot);
                }
              }
            });

            if (imported && action === 'add') {
              annotations.forEach(function (annot: any) {
                if (annot instanceof Annotations.WidgetAnnotation) {
                  if (annot.getCustomData('user') !== id) {
                    annot.Hidden = true;
                    annot.Listable = false;
                  }
                }
              });
            }

            if (draftDetails.status === draftStatus.SIGNATURE_ABORTED) {
              annotations.forEach((annot: any) => {
                annotationManager.deleteAnnotation(annot);
              });
            }
          }
        );

        if (!draftDetails?.open_collab) {
          UI.disableElements(['textPopup']);
        }
      });
    }
  }, [
    fileLink,
    documentContainer,
    setInstance,
    dragOver,
    draftDetails,
    drop,
    setAddedSignatureFields,
    user_data,
    isExternal,
    external_signatory_id,
    setDocumentLoading,
    internalSignatoryId,
  ]);

  useEffect(() => {
    if (!instance) return;
    if (signatureAnnot) {
      const { Annotations, documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();

      const id = isExternal
        ? external_signatory_id
        : internalSignatoryId || user_data?.email;
      annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.SignatureWidgetAnnotation &&
            annot.getCustomData('user') === id
        )
        .forEach((annot: any) => {
          if (annot.isSignedByAppearance()) return;
          const annotCopy = annotationManager.getAnnotationCopy(signatureAnnot);
          annotCopy.PageNumber = annot.getPageNumber();
          annotCopy.X = annot.getX();
          annotCopy.Y = annot.getY();
          annotationManager.addAnnotation(annotCopy);
          annot.sign(annotCopy);
        });

      setSignatureAnnot(null);
    }
  }, [
    external_signatory_id,
    instance,
    isExternal,
    signatureAnnot,
    user_data?.email,
  ]);

  return (
    <div
      ref={documentContainer}
      id="document-viewer"
      style={{ height: '90vh' }}
    />
  );
};

export default DocxViewerComponent;
