import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import TabIcon from '@mui/icons-material/Tab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import SupportDocsDrawer from './SupportDocsDrawer';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import {
  addDraftChecklistBulkComments,
  addDraftChecklistComments,
  deleteChecklistComment,
} from '../../../Services/Draft';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';

interface Props {
  setOpenUploadDialog: Dispatch<SetStateAction<any>>;
  handleOpenDeleteDialog: (id: string, data: any) => void;
  handleOpenAssigneeDialog: (data: any) => void;
  handleOpenUploadDialog: (id: string) => void;
  handleActiveStatus: (checkedValue: boolean, data: any) => void;
  checklistData: string[];
  isExternal?: boolean;
  userIsOwner: boolean;
  updateReminder?: any;
  user_data?: any;
  selectedDraftData?: any;
}

const Checklist: React.FC<Props> = ({
  handleOpenDeleteDialog,
  handleOpenAssigneeDialog,
  handleOpenUploadDialog,
  handleActiveStatus,
  checklistData,
  isExternal,
  userIsOwner,
  updateReminder,
  user_data,
  selectedDraftData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, reset } = useForm();
  const queryClient = useQueryClient();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [supportDocList, setSupportDocList] = useState<any[]>([]);
  const [activeChecklistId, setActiveChecklistId] = useState<number | null>(
    null
  );
  const [isCommentHovered, setIsCommentHovered] = useState<{
    [key: string]: boolean;
  }>({});

  const isOwner = selectedDraftData?.owners?.some(
    (owner: { id: string }) => owner?.id === user_data?.id
  );

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSupportDocList([]);
  };

  const handleCheckboxStatus = (
    reminder_id: string,
    checked: boolean,
    support_doc: any[]
  ) => {
    if (reminder_id) {
      const status = checked
        ? 'Completed'
        : support_doc && support_doc?.length > 0
          ? 'In Progress'
          : 'Not Started';

      const updateChecklistPayload = {
        id: reminder_id,
        body: {
          status: status,
        },
      };
      updateReminder(updateChecklistPayload);
    }
  };

  const toggleInput = useCallback((checklistId: any) => {
    setActiveChecklistId((prev) => (prev === checklistId ? null : checklistId));
  }, []);

  const { mutate: addChecklistCommentMutation } = useMutation({
    mutationFn: addDraftChecklistComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_checkList'],
      });
      reset();
    },
  });

  const { mutate: delete_checklist_comment } = useMutation({
    mutationKey: ['delete-checklist-comment'],
    mutationFn: deleteChecklistComment,
    onSuccess: () => {
      enqueueSnackbar('Comment deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_checkList'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Comment!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: addBulkCommentMutation } = useMutation({
    mutationKey: ['draft_checklist_bulk_comments'],
    mutationFn: addDraftChecklistBulkComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_checkList'],
      });
    },
  });

  const handleCommentHover = (commentId: string, isHovered: boolean) => {
    setIsCommentHovered((prevState) => ({
      ...prevState,
      [commentId]: isHovered,
    }));
  };

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [checklistData]);

  const handleCommentsClick = useCallback(
    (clickedApprover: any) => {
      const bulkCommentIds = clickedApprover?.comments
        ?.filter((comment: any) => !comment?.is_read)
        ?.map((comment: any) => comment?.id);
      const hasUnreadCommentsByOthers = clickedApprover?.comments?.some(
        (comment: any) =>
          comment?.created_by?.id !== user_data?.id &&
          !comment?.is_read &&
          clickedApprover?.user === comment?.commented_for?.id
      );
      const canCallApiAsOwner = !isOwner && hasUnreadCommentsByOthers;
      const canCallApiAsNonOwner =
        isOwner &&
        bulkCommentIds?.length > 0 &&
        clickedApprover?.comments?.some(
          (comment: any) =>
            !comment?.is_read &&
            comment?.created_by?.id !== clickedApprover?.created_by
        );

      if (
        bulkCommentIds.length > 0 &&
        (canCallApiAsOwner || canCallApiAsNonOwner)
      ) {
        const payload = {
          ids: bulkCommentIds,
          is_read: true,
        };
        addBulkCommentMutation(payload);
      }
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }
    },
    [addBulkCommentMutation, isOwner, user_data?.id]
  );

  const onSubmit = useCallback(
    (data: any, assigneeChecklist: any) => {
      const checklistItem = checklistData?.find(
        (checklist: any) => checklist?.user === assigneeChecklist?.user
      );
      const commentedForId = checklistItem ? (checklistItem as any)?.user : '';
      const payload = {
        checklist: assigneeChecklist?.id,
        comment: data?.[`comment-${assigneeChecklist?.user}`] || '',
        commented_for: commentedForId,
      };
      addChecklistCommentMutation(payload);
    },
    [checklistData, addChecklistCommentMutation, toggleInput]
  );

  return (
    <React.Fragment>
      <List sx={{ width: '100%', bgcolor: 'transparent' }}>
        {checklistData?.map((value: any) => {
          const fullName: string[] = value?.user_name?.split(' ');
          return (
            !value.deleted_status && (
              <Stack
                sx={{
                  background:
                    value?.comments?.length > 0 ||
                    value?.id === activeChecklistId
                      ? '#88305F1F'
                      : 'transparent',
                  borderRadius:
                    value?.comments?.length > 0 ||
                    value?.id === activeChecklistId
                      ? '8px'
                      : '0px',
                  marginBottom: '0.8rem',
                }}
              >
                <ListItem
                  key={value}
                  secondaryAction={
                    <Stack direction="row" alignItems="center">
                      {value?.support_doc?.length > 0 && (
                        <Stack direction="row" alignItems="center">
                          <Typography variant="caption">
                            {value?.support_doc?.length} attachment
                          </Typography>
                          <IconButton
                            edge="end"
                            onClick={() => handleOpenUploadDialog(value)}
                            disabled={value?.active_status}
                          >
                            <AttachFileIcon
                              style={{
                                rotate: '90deg',
                              }}
                            />
                          </IconButton>
                          <Tooltip title="Show connected documents">
                            <IconButton
                              edge="end"
                              sx={{ ml: 1 }}
                              onClick={() => {
                                setOpenDrawer(true);
                                setSupportDocList(value?.support_doc);
                              }}
                            >
                              <TabIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      )}
                      {value?.support_doc?.length === 0 && (
                        <IconButton
                          edge="end"
                          onClick={() => handleOpenUploadDialog(value)}
                          disabled={value?.active_status}
                        >
                          <FileUploadOutlinedIcon />
                        </IconButton>
                      )}
                      {!isExternal && (
                        <>
                          <IconButton
                            edge="end"
                            disabled={userIsOwner}
                            sx={{ ml: 1 }}
                            onClick={() => {
                              handleOpenDeleteDialog(value?.id, value);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                          {!value?.user_name && (
                            <IconButton
                              edge="end"
                              sx={{ ml: 1 }}
                              disabled={userIsOwner || value?.active_status}
                              onClick={() => handleOpenAssigneeDialog(value)}
                            >
                              <PersonAddAltOutlinedIcon />
                            </IconButton>
                          )}
                        </>
                      )}

                      {value?.comments?.length === 0 &&
                        value?.user_name &&
                        (isOwner || value?.user === user_data?.id) && (
                          <Tooltip title="Add Reply/Comment">
                            <IconButton
                              edge="end"
                              sx={{
                                ml: 1,
                                fontSize: '14px',
                                marginTop: '0.4rem',
                              }}
                              onClick={() => {
                                toggleInput(value?.id);
                              }}
                            >
                              <ChatBubbleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                      {value?.user_name && (
                        <Stack direction="row" alignItems="center">
                          <IconButton
                            edge="end"
                            sx={{ ml: 1 }}
                            disabled={userIsOwner || value?.active_status}
                            onClick={() =>
                              isExternal ? {} : handleOpenAssigneeDialog(value)
                            }
                          >
                            <NameAvatar
                              firstName={fullName?.[0]}
                              lastName={fullName?.[1]}
                            />
                          </IconButton>
                          {!value?.reminder?.due_date && (
                            <Tooltip title="Create Reminder">
                              <IconButton
                                disabled={userIsOwner || value?.active_status}
                              >
                                <EventIcon
                                  onClick={() =>
                                    isExternal
                                      ? {}
                                      : handleOpenAssigneeDialog(value)
                                  }
                                  sx={{
                                    fill: '#88305F',
                                    fontSize: '20px',
                                    marginLeft: '10px',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  }
                  disablePadding
                >
                  <ListItemButton
                    sx={
                      value?.comments?.length > 0 ||
                      value?.id === activeChecklistId
                        ? {
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }
                        : {}
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 'unset' }}>
                      <Checkbox
                        disabled={userIsOwner}
                        edge="start"
                        checked={value?.active_status}
                        onChange={(event: any) => {
                          handleActiveStatus(event.target.checked, value);
                          if (value?.reminder?.id) {
                            handleCheckboxStatus(
                              value?.reminder?.id,
                              event.target.checked,
                              value?.support_doc
                            );
                          }
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title={value?.checklist_name}>
                          <Box
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '110px',
                            }}
                          >
                            {value?.checklist_name}
                          </Box>
                        </Tooltip>
                      }
                    />
                  </ListItemButton>
                </ListItem>

                {(value?.comments?.length > 0 ||
                  value?.id === activeChecklistId) && (
                  <Stack spacing={2} sx={{ padding: '8px 12px 16px' }}>
                    {value?.comments?.length > 0 && (
                      <Accordion
                        sx={{
                          background: 'unset',
                          boxShadow: 'none',
                        }}
                      >
                        <AccordionSummary
                          sx={{ padding: 0 }}
                          onClick={() => {
                            handleCommentsClick(value);
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Typography variant="body2" fontWeight={600}>
                              Comments
                            </Typography>

                            {value?.unread_comment_count > 0 &&
                              value?.comments?.some(
                                (comment: any) =>
                                  !comment?.is_read &&
                                  comment?.created_by?.id !== user_data?.id &&
                                  value?.user !== value?.created_by
                              ) && (
                                <Typography
                                  variant="caption"
                                  fontWeight={600}
                                  color="#A0597F"
                                  border="2px solid"
                                  borderRadius="10px"
                                  padding="2px 6px"
                                  sx={{ borderStyle: 'dashed' }}
                                >
                                  {value?.unread_comment_count > 1
                                    ? `${value?.unread_comment_count} New Messages`
                                    : `${value?.unread_comment_count} New Message`}
                                </Typography>
                              )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                          <Stack spacing={2}>
                            <Scrollable
                              maxHeight={250}
                              ref={commentsContainerRef}
                            >
                              <Stack spacing={1} sx={{ paddingRight: '8px' }}>
                                {value?.comments
                                  ?.slice()
                                  .reverse()
                                  .map((comment: any) => (
                                    <Stack
                                      key={comment}
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                      onMouseEnter={() =>
                                        handleCommentHover(comment?.id, true)
                                      }
                                      onMouseLeave={() =>
                                        handleCommentHover(comment?.id, false)
                                      }
                                    >
                                      {comment?.created_by?.id !==
                                        user_data?.id && (
                                        <NameAvatar
                                          firstName={
                                            comment?.created_by?.first_name
                                          }
                                          lastName={
                                            comment?.created_by?.last_name
                                          }
                                          avatarStyle={{
                                            width: '40px',
                                            height: '40px',
                                          }}
                                        />
                                      )}
                                      <TextField
                                        key={comment?.id}
                                        value={comment?.comment}
                                        disabled
                                        fullWidth
                                      />
                                      {comment?.created_by?.id ===
                                        user_data?.id && (
                                        <NameAvatar
                                          firstName={
                                            comment?.created_by?.first_name
                                          }
                                          lastName={
                                            comment?.created_by?.last_name
                                          }
                                          avatarStyle={{
                                            width: '32px',
                                            height: '32px',
                                          }}
                                        />
                                      )}
                                      {isCommentHovered[comment?.id] &&
                                        comment?.created_by?.id ===
                                          user_data?.id && (
                                          <DeleteOutlineIcon
                                            sx={{
                                              cursor: 'pointer',
                                              fontSize: '20px',
                                              color: '#6D264C',
                                            }}
                                            onClick={() =>
                                              delete_checklist_comment(
                                                comment?.id
                                              )
                                            }
                                          />
                                        )}
                                    </Stack>
                                  ))}
                              </Stack>
                            </Scrollable>

                            {value?.comments?.length > 0 &&
                              (value?.user === user_data?.id || isOwner) && (
                                <Stack spacing={1}>
                                  <Stack
                                    component="form"
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    onSubmit={handleSubmit((data) =>
                                      onSubmit(data, value)
                                    )}
                                  >
                                    <ControlledTextField
                                      name={`comment-${value?.user}`}
                                      control={control}
                                      fullWidth
                                      sx={{
                                        background: '#FFF7FA',
                                        borderRadius: '6px',
                                      }}
                                    />
                                    <NameAvatar
                                      firstName={user_data?.first_name}
                                      lastName={user_data?.last_name}
                                      avatarStyle={{
                                        width: '32px',
                                        height: '32px',
                                      }}
                                    />
                                  </Stack>
                                  <Link
                                    fontSize="14px"
                                    display="flex"
                                    alignItems="center"
                                    whiteSpace="nowrap"
                                    sx={{
                                      textDecoration: 'unset',
                                    }}
                                  >
                                    <ChatBubbleOutlineIcon
                                      sx={{
                                        mr: '6px',
                                        fontSize: '14px',
                                        marginTop: '0.4rem',
                                      }}
                                    />
                                    Add Reply/Comment
                                  </Link>
                                </Stack>
                              )}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {value?.comments?.length === 0 &&
                      value?.id === activeChecklistId && (
                        <Stack
                          component="form"
                          spacing={1}
                          direction="row"
                          alignItems="center"
                          onSubmit={handleSubmit((data) =>
                            onSubmit(data, value)
                          )}
                        >
                          <ControlledTextField
                            name={`comment-${value?.user}`}
                            control={control}
                            fullWidth
                            sx={{ background: '#FFF7FA', borderRadius: '6px' }}
                          />
                          <NameAvatar
                            firstName={user_data?.first_name}
                            lastName={user_data?.last_name}
                            avatarStyle={{ width: '32px', height: '32px' }}
                          />
                        </Stack>
                      )}
                  </Stack>
                )}
              </Stack>
            )
          );
        })}
      </List>
      {openDrawer && (
        <SupportDocsDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          supportDocs={supportDocList}
          isExternal={isExternal}
        />
      )}
    </React.Fragment>
  );
};

export default Checklist;
