import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';

import UploadDraftDialog from './UploadDraftDialog';
import VersionTable from './VersionTable';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import ShareModal from '../../../DocumentLibrary/Component/ShareModal/ShareModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import {
  deleteCompareVersion,
  deleteDraftVersionHistory,
  fetchCompareVersionHistory,
  fetchDraftVersionId,
  fetchMergeTagDeviations,
  fetchVersionHistory,
} from '../../../Services/Draft';
import { uploadCompareVersion, uploadContractMetaData } from '../../DA';
import { draftStatus } from '../../State/DraftState';
import ComparisonDialog from '../ComparisonDialog';
import DraftViewAlert from '../DraftViewAlert';

interface Props {
  draftRowData: any;
  setDraftRowData: Dispatch<SetStateAction<any>>;
  handleVersionDrawerClose: VoidFunction;
}

const VersionDrawer: React.FC<Props> = ({
  draftRowData,
  setDraftRowData,
  handleVersionDrawerClose,
}) => {
  const [versionTableData, setVersionTableData] = useState<string[]>([]);
  const [versionHistory, setVersionHistory] = useState<any>([]);
  const [compareVersionHistory, setCompareVersionHistory] = useState<string[]>(
    []
  );
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [isShowCompareBtn, setIsShowCompareBtn] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(false);
  const [openUploadNew, setOpenUploadNew] = useState(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openComparisonDrawer, setOpenComparisonDrawer] = useState(false);
  const [compareFileName, setCompareFileName] = useState<any[]>([]);
  const [selectedDraftsLink, setSelectedDraftsLink] = useState<string[]>([]);
  const [falseMergeTags, setFalseMergeTags] = useState<any[]>([]);
  const [showDraftLockedAlert, setShowDraftLockedAlert] =
    useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const selectedDraftId = draftRowData?.draftID || draftRowData?.id;
  const queryClient = useQueryClient();

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const selectedComparedDrafts = useMemo(
    () => selectedRow?.filter((item: any) => item?.compare_versions),
    [selectedRow]
  );

  const userIsOwner = useMemo(
    () =>
      draftRowData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftRowData, user_data]
  );

  const userIsCreator = useMemo(
    () => draftRowData?.creator?.id === user_data?.id,
    [draftRowData, user_data]
  );

  const { isLoading: versionHistoryLoading } = useQuery({
    queryKey: ['version_history', selectedDraftId],
    queryFn: async () => {
      const response = await fetchVersionHistory(selectedDraftId);
      setVersionHistory(response?.results);
      return response?.results;
    },
    enabled: !!selectedDraftId,
  });

  useQuery({
    queryKey: ['compare_history', selectedDraftId],
    queryFn: async () => {
      const response = await fetchCompareVersionHistory(selectedDraftId);
      setCompareVersionHistory(response?.results);
      return response?.results;
    },
    enabled: !!selectedDraftId,
  });

  useQuery({
    queryKey: ['merge_tag_deviation', draftRowData?.id],
    queryFn: async () => {
      const response: any = await fetchMergeTagDeviations(draftRowData?.id);
      const allMergeTagsArray = response?.results;
      const filterData = allMergeTagsArray?.filter(
        (item: any) =>
          item?.error === false && item?.deviation_type === 'merge_tag'
      );
      setFalseMergeTags(filterData);
      return allMergeTagsArray;
    },
    enabled: !!draftRowData?.id,
  });

  const recallQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ['version_history', selectedDraftId],
    });
    queryClient.invalidateQueries({
      queryKey: ['compare_history', selectedDraftId],
    });
    queryClient.invalidateQueries({ queryKey: ['drafts'] });
  };

  useEffect(() => {
    if (versionHistory?.length !== 0 || compareVersionHistory?.length !== 0) {
      setVersionTableData([...versionHistory, ...compareVersionHistory]);
    }
  }, [versionHistory, compareVersionHistory]);

  const handleSelectionChange = (selection: any) => {
    const selectedRowData: any = versionTableData.filter((row: any) =>
      selection.includes(row.id)
    );

    // conditions for share draft button
    if (selectedRowData?.length === 1) {
      setCanShare(true);
    } else {
      setCanShare(false);
    }

    // conditions for compare drafts button
    if (
      selectedRowData?.length === 2 &&
      selectedRowData?.[0]?.version !== 0 &&
      selectedRowData?.[1]?.version !== 0
    ) {
      setIsShowCompareBtn(true);
      setSelectedDraftsLink([
        selectedRowData?.[0]?.id,
        selectedRowData?.[1]?.id,
      ]);
    } else {
      setIsShowCompareBtn(false);
    }

    setSelectedRow(selectedRowData);
  };

  const handleUploadModalClose = () => {
    setOpenUploadNew(false);
  };

  const { mutate: RequisitionVersionById } = useMutation({
    mutationFn: fetchDraftVersionId,
    onSuccess: (responseData) => {
      const url = responseData.access_url;
      const fileExtension = url.split('.').pop().split('?')[0];
      fetch(url)
        .then((r) => r.blob())
        .then((blobData) => {
          saveAs(blobData, `${responseData?.id}.${fileExtension}`);
        });
    },
  });

  const downloadVersionFile = async () => {
    if (userIsOwner) {
      RequisitionVersionById(selectedRow?.[0]?.id);
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const { mutate: uploadContractData } = useMutation({
    mutationFn: uploadContractMetaData,
    onSuccess: (responseData) => {
      setDraftRowData(responseData?.data);
      recallQuery();
    },
  });

  const openCreateDraftDialog = () => {
    if (
      draftRowData?.createFrom === 'template' &&
      (draftRowData?.status.toLowerCase() === 'draft creation pending' ||
        draftRowData?.version === 0)
    ) {
      enqueueSnackbar(
        'Version should not be uploaded until draft generation from template is approved',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      return;
    } else if (
      (draftRowData?.createFrom === 'counter_party' ||
        draftRowData?.createFrom === 'support_document') &&
      draftRowData?.status === 'Requisition Approval Pending'
    ) {
      enqueueSnackbar(
        'Version should not be uploaded until draft generation from counter party or requisition draft is approved',
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
      return;
    }
    setOpenUploadNew(true);
  };

  const handleContractNameClick = React.useCallback(() => {
    if (userIsOwner || userIsCreator) {
      openCreateDraftDialog();
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  }, [draftRowData]);

  const shareVersion = () => {
    if (userIsOwner) {
      if (selectedRow?.length === 1 && selectedRow[0]?.link) {
        if (selectedRow[0]?.is_locked) {
          setShowDraftLockedAlert(true);
        } else {
          setOpenShareModal(true);
        }
      } else {
        setOpenShareModal(true);
      }
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow([]);
  };

  const handleOpenDeleteDialog = () => {
    if (userIsOwner) {
      selectedRow.map((data: any) => {
        if (data?.version === 1) {
          enqueueSnackbar('V1 draft cannot be deleted.', {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return;
        } else {
          setOpenDeleteDialog(true);
        }
      });
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleCompareVersion = async () => {
    if (userIsOwner) {
      const higherDraft = selectedRow?.filter((item: any, index: any) => {
        if (item[index] > item[index + 1]) {
          return item?.draftID;
        } else {
          return item?.draftID;
        }
      });
      const name1 = `V${selectedRow?.[0]?.version}-${selectedRow?.[0]?.contractName}`;
      const name2 = `V${selectedRow?.[1]?.version}-${selectedRow?.[1]?.contractName}`;
      const payload = {
        draftID: higherDraft[0]?.draftID,
        version: higherDraft[0]?.version,
        compare_versions: {
          data: selectedRow,
          visible_version: `V${selectedRow?.[0].version}/V${selectedRow?.[1].version}`,
        },
      };
      uploadCompareVersion(payload);
      setOpenComparisonDrawer(true);
      setCompareFileName([name1, name2]);
    } else {
      enqueueSnackbar(
        'You are not the Owner of the draft, only Owner can perform this function.',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const { mutate: deleteDraftVersion } = useMutation({
    mutationFn: deleteDraftVersionHistory,
    onSuccess: () => {
      enqueueSnackbar('Version deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteDialog();
      recallQuery();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete the version!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteComparedDraft } = useMutation({
    mutationFn: deleteCompareVersion,
    onSuccess: () => {
      enqueueSnackbar('Compare version deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteDialog();
      recallQuery();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete the compare version!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleDeleteConfirmation = () => {
    const selectedVersionDraft = selectedRow?.filter(
      (item: any) => !item?.compare_versions && item?.version !== 1
    );
    if (selectedVersionDraft?.length > 0) {
      const draft_ids = selectedVersionDraft?.map((item: any) => item?.id);
      deleteDraftVersion(draft_ids);
    }
    if (selectedComparedDrafts?.length > 0) {
      const compare_ids = selectedComparedDrafts.map((item: any) => item?.id);
      deleteComparedDraft(compare_ids);
    }
    handleVersionDrawerClose();
  };

  return (
    <Stack spacing={2} sx={{ margin: '15px' }}>
      <Typography fontSize="13px">
        Use Version History to upload a new version, compare previous versions
        and download previous and current versions.
      </Typography>

      <Typography fontSize="13px">
        To compare two versions, start by selecting any two versions below and
        use the icon to compare.
      </Typography>

      <Grid container justifyContent="space-between" alignItems="center">
        <Typography fontSize="13px">
          {versionTableData?.length} Version found
        </Typography>

        <Stack direction="row" spacing={1}>
          {selectedRow.length === 0 && (
            <Button
              variant="text"
              startIcon={<FileUploadOutlinedIcon />}
              disabled={
                draftRowData?.status === draftStatus?.SIGNATURE_ABORTED ||
                draftRowData?.status ===
                  draftStatus?.COUNTER_PARTY_DRAFT_CREATED ||
                draftRowData?.status === draftStatus?.DRAFT_UPLOAD_PENDING
                  ? false
                  : (draftRowData?.createFrom === 'template'
                      ? draftRowData?.version === 0 ||
                        (draftRowData?.version === 1 &&
                          draftRowData?.status ===
                            draftStatus?.DRAFT_CREATION_PENDING) ||
                        (draftRowData?.status ===
                          draftStatus?.INTERNAL_DRAFT_CREATED &&
                          falseMergeTags?.length > 0 &&
                          draftRowData?.version === 1)
                        ? true
                        : false
                      : false) ||
                    draftRowData?.status !== draftStatus?.INTERNAL_DRAFT_CREATED
              }
              onClick={handleContractNameClick}
            >
              Upload a new version
            </Button>
          )}
          {selectedRow?.length === 1 &&
            selectedComparedDrafts?.length === 0 && (
              <Stack direction="row" spacing={1}>
                {canShare && (
                  <IconButton onClick={() => shareVersion()}>
                    <ShareOutlinedIcon />
                  </IconButton>
                )}
                <IconButton onClick={() => downloadVersionFile()}>
                  <SaveAltOutlinedIcon />
                </IconButton>
              </Stack>
            )}
          {selectedRow?.length === 2 &&
            selectedComparedDrafts?.length === 0 &&
            isShowCompareBtn && (
              <IconButton onClick={() => handleCompareVersion()}>
                <CompareOutlinedIcon />
              </IconButton>
            )}
          {selectedRow?.length > 0 && (
            <IconButton onClick={handleOpenDeleteDialog}>
              <DeleteOutlinedIcon />
            </IconButton>
          )}
        </Stack>
      </Grid>

      <VersionTable
        versionTableData={versionTableData}
        handleSelectionChange={handleSelectionChange}
        loadingTable={versionHistoryLoading}
        draftRowData={draftRowData}
      />

      {openUploadNew && (
        <UploadDraftDialog
          open={openUploadNew}
          onClose={handleUploadModalClose}
          draftData={draftRowData}
          uploadContractData={uploadContractData}
        />
      )}

      {openShareModal && (
        <ShareModal
          openModal={openShareModal}
          handleCloseShareBtn={() => setOpenShareModal(false)}
          selectedList={selectedRow}
          type="stylus"
          draftId={draftRowData?.id}
        />
      )}

      <ReusableConfirmationModal
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmation}
        title="Delete Draft Version"
        confirmBtnText="Yes, Delete"
        cancelBtnText="Cancel"
      >
        <Typography>
          Are you sure you want to delete these drafts version ?
        </Typography>
      </ReusableConfirmationModal>

      {openComparisonDrawer && (
        <ComparisonDialog
          openComparisonDrawer={openComparisonDrawer}
          setComparisonDrawer={setOpenComparisonDrawer}
          draftDetails={draftRowData}
          selectedDraftsLink={selectedDraftsLink}
          onClose={() => {
            setSelectedDraftsLink([]);
          }}
          compareFileName={compareFileName}
        />
      )}

      {showDraftLockedAlert && (
        <DraftViewAlert
          isOpen={showDraftLockedAlert}
          onClose={() => setShowDraftLockedAlert(false)}
          data={selectedRow?.[0]?.locked_by}
        />
      )}
    </Stack>
  );
};

export default VersionDrawer;
