import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import {
  removeLocalStorage,
  removeSessionStorage,
} from '../../../Authentication/Actions/authentication';
import {
  deleteSupportDocFromChecklistExternal,
  upload_file_in_s3_bucket_external,
  uploadNewDocumentExternal,
} from '../../../ExternalUserFlow/Services/Draft';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import { upload_file_in_s3_bucket } from '../../../Services/DocumentLibrary';
import {
  addNewChecklist,
  deleteSupportDocFromChecklist,
  uploadNewDocument,
} from '../../../Services/Draft';

interface IProps {
  open: boolean;
  handleCloseModal: VoidFunction;
  isExternal?: boolean;
  checklistItemData: any;
  draftId: string;
  externalToken?: string;
  documentList: any[];
  updateReminder?: any;
}

const SparkMD5 = require('spark-md5');

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6D264C' : '#308fe8',
  },
}));

const UploadSupportDocs: React.FC<IProps> = (props) => {
  const {
    open,
    handleCloseModal,
    isExternal,
    checklistItemData,
    draftId,
    externalToken,
    documentList,
    updateReminder,
  } = props;
  const [uploadedSupportFile, setUploadedSupportFile] = useState<any>([]);
  const [uploadedSupportData, setUploadedSupportData] = useState<any[]>([]);
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(true);
  const [deletedSupportId, setDeletedSupportId] = useState<string>('');
  const [uploadedAllFile, setUploadedAllFile] = useState<any>({});
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');
  const [checklistId, setChecklistId] = useState<string>('');

  const { control } = useForm();

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUploadedSupportData(documentList);
  }, [documentList]);

  useEffect(() => {
    if (!uploadedSupportData?.length) {
      setIsShowUploadField(true);
    }
  }, [uploadedSupportData]);

  const onUploadProgress = useCallback(
    (progressEvent: any, fileData: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = fileData;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedSupportFile]
  );

  const handleSaveSupportDocs = () => {
    queryClient.invalidateQueries({
      queryKey: ['draft_checkList'],
    });
    handleCloseModal();
    if (isExternal) {
      setTimeout(() => {
        removeLocalStorage('external_email');
        removeLocalStorage('external_token');
        removeSessionStorage('external_auth_token');
        window.close();
      }, 3000);
    }
    if (checklistItemData?.reminder_id && uploadedAllFile?.length > 0) {
      const updateChecklistPayload = {
        id: checklistItemData.reminder_id,
        body: { status: 'In Progress' },
      };
      updateReminder(updateChecklistPayload);
    }
  };

  const { mutate: uploadDocInS3Bucket, isPending: s3BucketLoading } =
    useMutation({
      mutationKey: ['upload_document_in_S3_bucket'],
      mutationFn: isExternal
        ? upload_file_in_s3_bucket_external
        : upload_file_in_s3_bucket,
      onSuccess: () => {
        enqueueSnackbar('Document uploaded successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to upload the document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadDocument, isPending } = useMutation({
    mutationKey: ['upload_draft_document', uploadedSupportFile],
    mutationFn: isExternal ? uploadNewDocumentExternal : uploadNewDocument,
    onSuccess: (response: any) => {
      if (response?.data) {
        setUploadedSupportData((prevData) => [
          response?.data?.support_doc,
          ...prevData,
        ]);
        setIsShowUploadField(false);
        Object.entries(uploadedAllFile)?.map(([key]) => {
          if (
            response?.data?.support_doc?.file_name ===
            uploadedAllFile[key]?.name
          ) {
            const file = uploadedAllFile[key];
            if (file) {
              const onHandleFileProgress = {
                onUploadProgress: (progressEvent: any) =>
                  onUploadProgress(progressEvent, file),
              };
              uploadDocInS3Bucket({
                presignedPostData: response?.data?.presigned_url,
                file: file,
                onHandleFileProgress: onHandleFileProgress,
              });
            }
          }
        });
      }
    },
    onError: (error: any) => {
      const message = error?.response?.data?.__all__;
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteDoc, isPending: isLoadingDeleteDoc } = useMutation({
    mutationKey: ['delete_support_document', deletedSupportId],
    mutationFn: isExternal
      ? deleteSupportDocFromChecklistExternal
      : deleteSupportDocFromChecklist,
    onSuccess: (data, deletedSupportId) => {
      enqueueSnackbar('Document deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setUploadedSupportData((prevData) =>
        prevData.filter((item) => item?.id !== deletedSupportId)
      );
      handleCloseDeleteModal();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete the document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const upload_support_docs = (uploadedFiles: any, checklistId: string) => {
    Object.entries(uploadedFiles)?.map(([key, value]) => {
      setUploadedSupportFile({ [key]: value });
      const reader = new FileReader();
      const file = uploadedFiles[key];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const file_: any = {
            file_name: file.name,
            file_hash: hexHash,
            file_size: file.size,
            draft_checklist: checklistId,
            ...(isExternal && {
              external_token: externalToken,
            }),
          };
          uploadDocument(file_);
        };
      }
    });
  };

  const { mutate: addChecklistItem } = useMutation({
    mutationFn: addNewChecklist,
    onSuccess: (response: any) => {
      setChecklistId(response?.data?.id);
      queryClient.invalidateQueries({
        queryKey: ['draft_checkList'],
      });
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      upload_support_docs(uploadedAllFile, response?.data?.id);
    },
    onError: (error: any) => {
      const message = error?.response?.data?.__all__;
      enqueueSnackbar(`${message || 'Failed to add new checklist item!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleFileUploadChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile: any = event.target.files;
    setUploadedAllFile(uploadedFile);
    if (checklistItemData?.id || checklistId) {
      const checklistItemId = checklistItemData?.id || checklistId;
      upload_support_docs(uploadedFile, checklistItemId);
    } else {
      const payload = {
        ...checklistItemData,
        draft: draftId,
      };
      addChecklistItem(payload);
    }
  };

  const handleDeleteSupportDocs = (id: string) => {
    deleteDoc(id);
    setDeletedSupportId(id);
  };

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const handleClose = () => {
    if (isLoadingDeleteDoc || isPending || s3BucketLoading) {
      enqueueSnackbar("Don't close modal because process is going on!", {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      handleSaveSupportDocs();
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        title={`Upload for “${checklistItemData?.checklist_name}”`}
        open={open}
        handleClose={handleCloseModal}
      >
        <Stack spacing={2} mt="25px" width="550px">
          {isShowUploadField && (
            <Controller
              name="upload_contract"
              control={control}
              render={() => (
                <TextField
                  variant="outlined"
                  label="Upload file"
                  fullWidth
                  disabled
                  helperText="Supported file formats: PDF, DOCX"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor={`upload-doc`}>
                          <input
                            type="file"
                            multiple
                            id={`upload-doc`}
                            style={{ display: 'none' }}
                            accept="pdf, docx"
                            onChange={(e) => handleFileUploadChanges(e)}
                          />
                          <IconButton component="span">
                            <FileUploadOutlinedIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}

          {(isPending || s3BucketLoading) && (
            <Box sx={{ width: '100%', padding: '15px 0' }}>
              <LinearProgress />
            </Box>
          )}

          {uploadedSupportData?.length > 0 &&
            uploadedSupportData?.map((item: any, index: number) => (
              <Stack
                key={index}
                direction="column"
                spacing={1}
                paddingBottom="15px"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize="17px" fontWeight={600}>
                    {item?.file_name || item?.name}
                  </Typography>
                  <LoadingButton
                    disabled={isLoadingDeleteDoc}
                    size="small"
                    onClick={() => handleOpenDeleteModal(item?.id)}
                    loading={isLoadingDeleteDoc}
                  >
                    <DeleteOutlineIcon />
                  </LoadingButton>
                </Stack>
                <BorderLinearProgress variant="determinate" value={100} />
              </Stack>
            ))}

          <Stack direction="row" spacing={2} pt="15px">
            {uploadedSupportData?.length === 0 && (
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            )}
            {uploadedSupportData?.length > 0 && (
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  disabled={isLoadingDeleteDoc}
                  loading={s3BucketLoading || isPending}
                  variant="contained"
                  onClick={handleSaveSupportDocs}
                  style={{ fontSize: '15px', padding: '3px 15px' }}
                >
                  Save and close
                </LoadingButton>
                {!isShowUploadField && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setIsShowUploadField(true)}
                  >
                    Upload more
                  </Button>
                )}
                <LoadingButton
                  onClick={handleClose}
                  startIcon={<CloseIcon />}
                  disabled={s3BucketLoading || isPending || isLoadingDeleteDoc}
                  loadingPosition="end"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CustomModal>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => handleDeleteSupportDocs(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default UploadSupportDocs;
